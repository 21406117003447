import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import { setNotifyAllDialog } from "src/actions/notification";
import NotifyAllForm from "../../form/NotifyAllForm";
import { IoIosNotifications } from "react-icons/io";

const NotificationHeader = () => {
  const dispatch = useDispatch();

  const _NotifyAll = useSelector((state) => state.notification.notifyAllDialog);

  return (
    <>
      <Helmet>
        <title>Notifications | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Notifications
              </Typography>
            }
            avatar={<IoIosNotifications size={20} />}
            action={
              <RolePermission permission="50-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(setNotifyAllDialog())}
                >
                  Notify All
                </Button>
              </RolePermission>
            }
          />
          {_NotifyAll ? <NotifyAllForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default NotificationHeader;

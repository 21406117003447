import {
  NOTIFICATION_RELOAD,
  PERSONAL_NOTIFICATION_GET_ALL,
  GET_EXCHANGE_RATE,
  NOTIFICATION_GET_ALL,
  NOTIFICATION_GET_BY_ID,
  NOTIFICATION_SEARCH_BODY,
  SEGMENT_NOTIFICATION_RESET_FORM,
  NOTIFY_ALL_NOTIFICATION_RESET_FORM,
  NOTIFY_SEGMENT_DIALOG,
  NOTIFY_BY_USER_ID_DIALOG,
  NOTIFY_ALL_DIALOG,
} from "../actions/type";

const initialState = {
  reload: false,
  notifyAllDialog: false,
  notifySegmentDialog: false,
  notifyByUserIdDialog: false,
  personalNotificationData: [],
  exchangeRate: "",
  searchBody: {},
  notifySegmentForm: {
    id: null,
    segmentType: 0,
    region: 1,
    startDate: null,
    endDate: null,
    provinceId: null,
    cityId: null,
    minWalletBallance: 0,
    maxWalletBalance: 0,
    hasBalance: false,
    gender: 0,
    hasOrders: false,
    titleKU: "",
    titleEn: "",
    titleAr: "",
    messageKu: "",
    messageEn: "",
    messageAr: "",
    imageUrl: "",
  },
  notifyAllForm: {
    id: null,
    titleKU: "",
    titleEn: "",
    titleAr: "",
    messageKu: "",
    messageEn: "",
    messageAr: "",
    isIos: false,
    isAndroid: false,
    imageUrl: "",
  },
  data: {
    data: [],
    total: 0,
    rowCount: 0,
  },
};

function notificationReducer(notification = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PERSONAL_NOTIFICATION_GET_ALL:
      notification.personalNotificationData = payload;
      return notification;

    case GET_EXCHANGE_RATE:
      notification.exchangeRate = payload;
      return notification;

    case NOTIFICATION_GET_ALL:
      notification.data.data = payload.data;
      notification.data.total = payload.total;
      notification.data.rowCount = payload.rowCount;
      return notification;

    case NOTIFICATION_GET_BY_ID:
      notification.form = payload;
      return notification;

    case SEGMENT_NOTIFICATION_RESET_FORM:
      notification.notifySegmentForm = initialState.notifySegmentForm;
      return notification;

    case NOTIFY_ALL_NOTIFICATION_RESET_FORM:
      notification.notifyAllForm = initialState.notifyAllForm;
      return notification;

    case NOTIFICATION_RELOAD:
      return { ...notification, reload: !notification.reload };

    case NOTIFY_ALL_DIALOG:
      return {
        ...notification,
        notifyAllDialog: !notification.notifyAllDialog,
      };

    case NOTIFY_SEGMENT_DIALOG:
      return {
        ...notification,
        notifySegmentDialog: !notification.notifySegmentDialog,
      };

    case NOTIFY_BY_USER_ID_DIALOG:
      return {
        ...notification,
        notifyByUserIdDialog: !notification.notifyByUserIdDialog,
      };

    case NOTIFICATION_SEARCH_BODY:
      return { ...notification, searchBody: payload };

    default:
      return notification;
  }
}

export default notificationReducer;

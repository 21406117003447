import { Typography, Chip, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userPhoneCodeHistoryDialog } from "src/actions/users";
import { CustomDialog } from "src/components/CustomDialog";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";

const UserPhoneCodeHistory = () => {
  const dispatch = useDispatch();

  const { data, dialog } = useSelector((state) => state.users.phoneCode);

  var columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "attemptCount",
      headerName: "Attempt Count",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "expiresAt",
      headerName: "Expires At",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack spacing={0} alignItems="center">
          <Typography>
            {moment(params.row.expiresAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption" color="red">
            {moment(params.row.expiresAt).format("HH:mm:ss a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "channel",
      headerName: "Channel",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "isUsed",
      headerName: "Is Used",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.isUsed ? "Yes" : "No"}
          color={params.row.isUsed ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack spacing={0} alignItems="center">
          <Typography>
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("HH:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <CustomDialog
      isOpen={dialog}
      handleClose={() => {
        dispatch(userPhoneCodeHistoryDialog());
      }}
      maxWidth="lg"
      title={
        <Typography variant="body">
          Phone Code History For: ({data?.countryCode}) {data?.phoneNumber}{" "}
        </Typography>
      }
    >
      <Box sx={{ height: { md: "calc(100vh - 277px)", xs: 1000 } }}>
        <DataGrid
          sx={{
            "& .css-204u17-MuiDataGrid-main": {
              paddingLeft: "15px",
            },
            "& .css-f3jnds-MuiDataGrid-columnHeaders": {
              paddingLeft: "15px",
            },
            ".MuiDataGrid-cell": {
              justifyContent: "center",
            },
          }}
          rows={
            data.info?.map((x, index) => {
              return { ...x, id: index + 1 };
            }) || []
          }
          columns={columns}
          rowHeight={51}
          disableSelectionOnClick
          paginationMode="client"
        />
      </Box>
    </CustomDialog>
  );
};

export default UserPhoneCodeHistory;

import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Typography,
  Stack,
  Chip,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useNavigate } from "react-router";
import { IoMdCart } from "react-icons/io";
import { country_codes } from "src/utils/common";
import ReactCountryFlag from "react-country-flag";
import DateRangepicker from "src/components/DateRangePicker";
import {
  getAllUnCompletedCart,
  saveUsersUnCompletedCartPageNum,
  saveUsersUnCompletedCartSearchBody,
} from "src/actions/uncompletedCarts";

export default function UncompletedCartsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _UsersCart = useSelector((state) => state.uncompletedCarts.usersData);
  const loading = useSelector((state) => state.uncompletedCarts.reload);
  const pageNo = useSelector((state) => state.uncompletedCarts.pageNo);
  const searchBody = useSelector((state) => state.uncompletedCarts.searchBody);

  const [page, setPage] = useState(pageNo);
  const [phoneNumber, setPhoneNumber] = useState(searchBody.phoneNumber);
  const [promoCodeName, setPromoCode] = useState(searchBody.promoCodeName);
  const [fromTotalAmount, setFromTotalAmountAfterDiscount] = useState(
    searchBody.fromTotalAmount
  );
  const [toTotalAmount, setToTotalAmountAfterDiscount] = useState(
    searchBody.toTotalAmount
  );

  const toBeDelivered = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  useEffect(() => {
    dispatch(getAllUnCompletedCart(page + 1, searchBody));
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveUsersUnCompletedCartPageNum(newPage));
    setPage(newPage);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.4,
    },
    {
      field: "lastModifiedAt",
      headerName: "Last Modified At",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="body" component="div">
            {moment(params.row.lastModifiedAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body" component="div">
            {moment(params.row.lastModifiedAt).format("hh:mm A")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "userName",
      headerName: "Customer Info",
      flex: 1.5,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="body" component="div">
            {params.row.user?.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <ReactCountryFlag
              style={{
                width: "36px",
                height: "28px",
                border: "solid 1px #dedede",
              }}
              svg
              countryCode={
                country_codes.filter(
                  (code) => code.dial_code === params.row.user?.countryCode
                )[0]?.code
              }
            />
            <Typography variant="body">
              {params.row.user?.phoneNumber}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      field: "address",
      headerName: "Customer Address",
      flex: 1,
      renderCell: (params) => params.row.user?.address,
    },
    {
      field: "itemsCount",
      headerName: "Items Count",
      flex: 1,
    },
    {
      field: "balance",
      headerName: "Balance $",
      flex: 1,
      renderCell: (params) => params.row?.user?.balance?.toLocaleString(),
    },
    {
      field: "neededBalance",
      headerName: "Needed Balance $",
      flex: 1,
      renderCell: (params) => params.row?.neededBalance?.toLocaleString(),
    },
    {
      field: "promoCodeName",
      headerName: "Promo Code",
      flex: 1,
      renderCell: (params) =>
        params.row?.promoCodeNameName ? (
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="body" component="div" color="green">
              {params.row?.promoCodeNameName}
            </Typography>
            <Typography variant="body" component="div">
              {params.row?.totalDiscount?.toLocaleString()} $
            </Typography>
          </Stack>
        ) : null,
    },
    {
      field: "toBeDelivered",
      headerName: "Delivery",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={1} alignItems="center">
          <Chip
            label={params.row.toBeDelivered ? "Yes" : "No"}
            color={params.row.toBeDelivered ? "primary" : "default"}
          />
          {params.row?.toBeDelivered ? (
            <Typography variant="body">{params.row.deliveryCost} $</Typography>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "isInsured",
      headerName: "Insurance",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={1} alignItems="center">
          <Chip
            label={params.row.isInsured ? "Yes" : "No"}
            color={params.row.isInsured ? "primary" : "default"}
          />
          {params.row?.isInsured ? (
            <Typography variant="body">{params.row.insuranceFee} $</Typography>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Amount $",
      flex: 1,
      renderCell: (params) => params.row?.totalAmount?.toLocaleString(),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title="Order details">
          <IconButton
            onClick={(e) => {
              navigate(`/app/cart/${params.row.id}`);
            }}
          >
            <IoMdCart />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/^0+/, ""));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                      promoCodeName: promoCodeName,
                      fromTotalAmount: fromTotalAmount,
                      toTotalAmount: toTotalAmount,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Promo Code"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={promoCodeName}
              onChange={(e) => {
                setPromoCode(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                      promoCodeName: promoCodeName,
                      fromTotalAmount: fromTotalAmount,
                      toTotalAmount: toTotalAmount,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="From Total Amount"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={fromTotalAmount}
              onChange={(e) => {
                setFromTotalAmountAfterDiscount(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                      promoCodeName: promoCodeName,
                      fromTotalAmount: fromTotalAmount,
                      toTotalAmount: toTotalAmount,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="To Total Amount"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={toTotalAmount}
              onChange={(e) => {
                setToTotalAmountAfterDiscount(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                      promoCodeName: promoCodeName,
                      fromTotalAmount: fromTotalAmount,
                      toTotalAmount: toTotalAmount,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={toBeDelivered}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Active Delivery" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveUsersUnCompletedCartSearchBody({
                    ...searchBody,
                    phoneNumber: phoneNumber,
                    promoCodeName: promoCodeName,
                    fromTotalAmount: fromTotalAmount,
                    toTotalAmount: toTotalAmount,
                    toBeDelivered: value?.key,
                  })
                );
                setPage(0);
              }}
              value={
                toBeDelivered.filter(
                  (x) => x.key === searchBody.toBeDelivered
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangepicker
              startDate={searchBody.fromLastModifiedAt}
              endDate={searchBody.toLastModifiedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromDate: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.fromDate !== searchBody.fromDate ||
                  updatedFilter.toDate !== searchBody.toDate
                ) {
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      ...searchBody,
                      fromLastModifiedAt: moment(
                        searchDate[0].startDate
                      ).format("YYYY-MM-DD"),
                      toLastModifiedAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                      phoneNumber: phoneNumber,
                      promoCodeName: promoCodeName,
                      fromTotalAmount: fromTotalAmount,
                      toTotalAmount: toTotalAmount,
                    })
                  );
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setPhoneNumber("");
                  setPromoCode("");
                  setToTotalAmountAfterDiscount("");
                  setFromTotalAmountAfterDiscount("");
                  setPage(0);
                  dispatch(
                    saveUsersUnCompletedCartSearchBody({
                      fromLastModifiedAt: null,
                      toLastModifiedAt: null,
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 325px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _UsersCart?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={_UsersCart?.rowCount}
                  pageSize={searchBody.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveUsersUnCompletedCartSearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  CardContent,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Box,
  LinearProgress,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getCartReview, saveSearchBody } from "src/actions/cart";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { getAllVendors } from "src/actions/vendors";
import Close from "@mui/icons-material/Close";

export default function CartReviewTable() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const _SearchBody = useSelector((state) => state.cart.searchBody);
  const vendors = useSelector((state) => state.vendors.dataList);

  const [refresh, setRefresh] = useState(false);
  const handlePageChange = (newPage) => {
    dispatch(
      saveSearchBody({
        ..._SearchBody,
        page: newPage,
      })
    );
    setRefresh(!refresh);
  };
  const [cartReview, setCartReview] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(_SearchBody.phoneNumber ?? "");

  useEffect(() => {
    dispatch(getAllVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReload(true);
    dispatch(getCartReview(_SearchBody.page + 1, _SearchBody)).then((res) => {
      setCartReview(res.data.data);
      setRowCount(res.data.rowCount);
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, _SearchBody]);

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.2,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "customer",
      headerName: "Customer Information",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" spacing={0}>
          <Typography>{params.row.customer.fullName}</Typography>
          <Typography>{params.row.customer.phoneNumber}</Typography>
        </Stack>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total amount ($)",
      flex: 1,
    },
    {
      field: "needReviewAt",
      headerName: "Request date",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.needReviewAt).format("YYYY-MM-DD / hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      flex: 0.7,
      renderCell: (params) => (
        <Stack direction="column">
          <Typography>{params.row.reviewer}</Typography>
          <Typography variant="caption">
            {params.row.reviewAt
              ? moment(params.row.reviewAt).format("YYYY-MM-DD / hh:mm a")
              : ""}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "promoCode",
      headerName: "Promo Code",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      renderCell: (params) => (
        <Tooltip title="View cart details">
          <IconButton
            onClick={(event) => {
              if (event.button === 0) {
                navigate(`/app/cart/${params.row.id}`);
              }
            }}
            onContextMenu={(event) => {
              window.open(`/app/cart/${params.row.id}`, "_blank");
            }}
          >
            <Icon>shopping_cart</Icon>
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Card>
      <Box>
        <Card>
          <CardContent style={{ paddingBottom: 15 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  disabled={reload}
                  size="small"
                  placeholder="Phone Number"
                  variant="outlined"
                  type="number"
                  value={phoneNumber}
                  onChange={(e) => {
                    var phoneNumber = e.target.value;
                    setPhoneNumber(phoneNumber);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(
                        saveSearchBody({
                          ..._SearchBody,
                          phoneNumber: phoneNumber,
                        })
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={reload}
                  multiple
                  id="vendors"
                  options={vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    dispatch(
                      saveSearchBody({
                        ..._SearchBody,
                        vendorIds: value ? ids : _SearchBody.vendorIds,
                        phoneNumber: phoneNumber,
                      })
                    );
                  }}
                  value={vendors?.filter((x) =>
                    _SearchBody.vendorIds
                      ? _SearchBody.vendorIds.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={_SearchBody.type}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            ..._SearchBody,
                            type: "all",
                          })
                        );
                        setRefresh(!refresh);
                      }}
                    />
                    <FormControlLabel
                      value="notcomplete"
                      control={<Radio />}
                      label="Not Complete"
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            ..._SearchBody,
                            type: "notcomplete",
                          })
                        );
                        setRefresh(!refresh);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3} md={2}>
                <Stack direction="row" sx={{ justifyContent: "start" }}>
                  <IconButton
                    disabled={reload}
                    onClick={() => {
                      dispatch(
                        saveSearchBody({
                          page: 0,
                          type: "all",
                          vendorIds: "",
                          phoneNumber: "",
                        })
                      );
                      setPhoneNumber("");
                    }}
                  >
                    <Tooltip title="Clear filter">
                      <Close />
                    </Tooltip>
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <PerfectScrollbar>
        <Box sx={{ margin: 2 }}>
          <Box sx={{ height: { md: "calc(100vh - 300px)", xs: 1000 } }}>
            <DataGrid
              sx={{
                ".highlight": {
                  bgcolor: "#bdbdbd",
                  "&:hover": {
                    bgcolor: "darkgrey",
                  },
                },
                ".quantity": {
                  justifyContent: "center",
                },
              }}
              loading={reload}
              rows={cartReview}
              columns={columns}
              rowHeight={70}
              disableSelectionOnClick
              pagination
              pageSize={20}
              page={_SearchBody?.page ?? 0}
              onPageChange={handlePageChange}
              rowCount={rowCount}
              paginationMode="server"
              rowsPerPageOptions={[10, 25, 100]}
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </Box>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import MainLayout from "./components/MainLayout";
import Users from "src/pages/cross/Users";
import Vendors from "src/pages/cross/vendors/Vendors";
import VendorsCategory from "src/pages/cross/vendors/VendorsCatagory";
import Orders from "src/pages/cross/orders/Index";
import ExternallItem from "src/pages/cross/externalItem/Index";
import Cart from "src/pages/cross/cart";
import ReferralCode from "src/pages/cross/ReferralCode";
import ShippingsConfigs from "src/pages/cross/Configuration/shippingConfig";
import Banner from "src/pages/cross/banner";
import Dashboard from "src/pages/Dashboard";
import ExpensesList from "src/components/pagesContent/expenses/ExpensesContent";
import ExpenseTypesList from "src/components/pagesContent/expensesType/ExpensesTypeContent";
import ImageUploadCenter from "src/pages/cross/Configuration/ImageUploadCenter/Index";
import EPayments from "src/pages/cross/Configuration/EPayments/Index";
import GeneralSetting from "src/pages/cross/Configuration/Settings/Index";
import AddressesList from "src/components/pagesContent/addresses/addressesContent";
import MobileAppVersion from "src/pages/cross/Configuration/MobileAppVersion";
import CartReview from "src/pages/cross/cartReview";
import AgentRequests from "src/pages/AgentRequests";
import PopUpPost from "src/pages/cross/popUpPost";
import WalletReport from "src/pages/WalletReports";
import ReportNew from "src/pages/reportsNew";
import Statistic from "src/pages/cross/Ticket/Statistics/Index";
import Tickets from "src/pages/cross/Ticket/Index";
import TicketDetails from "src/pages/cross/Ticket/ticketDetails";
import TicketCategory from "src/pages/cross/Ticket/category";
import Departments from "src/pages/cross/department/DepartmentsConfig";
import Employee from "src/pages/cross/department/Employee/Index";
import Permissions from "src/pages/cross/dynamicAuth";
import PromotedItems from "src/pages/cross/deals/PromotedItems/Index";
import Deals from "src/pages/cross/deals/deals";
import HomeItems from "src/pages/cross/homeItems";
import UserOrders from "src/pages/cross/userOrders";
import WelcomeMessage from "src/components/welcomePage";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import Reasons from "src/pages/cross/Configuration/reasons";
import ProfileSettingsView from "src/pages/Profile";
import RatingQuestions from "src/pages/cross/ratingQuestions/Index";
import ContactInfo from "src/pages/cross/Configuration/contactInfo/Index";
import FAQ from "src/pages/shared/faq/Index";
import OrderRating from "src/pages/shared/orderRating";
import GiftOrders from "src/pages/gifts/orders/Index";
import Wallet from "src/pages/Wallet";
import Onboarding from "src/pages/cross/Configuration/onboarding/Index";
import Promos from "src/pages/cross/promos";
import UncompletedCarts from "src/pages/cross/uncompletedCarts";
import Shipments from "src/pages/cross/shipments/Index";
import OrderItems from "src/pages/cross/orderItems/List";
import PurchasingBill from "src/pages/cross/purchasingBill";
import Agents from "src/pages/cross/Configuration/agentCityConfig/Index";
import GoIp from "src/pages/shared/goip";
import UnknownItems from "src/pages/cross/unknownItems";
import Notification from "src/pages/shared/notification/Index";

const token = JSON.parse(localStorage.getItem("decodedIems"));

const Routes = () => {
  const route = [
    {
      path: "app",
      element: <DashboardLayout />,
      children: [
        { path: "users", element: <Users /> },
        { path: "vendors/all_vendors", element: <Vendors /> },
        { path: "vendors/vendors_category", element: <VendorsCategory /> },
        { path: "orders", element: <Orders /> },
        { path: "order_items", element: <OrderItems /> },
        { path: "order/:id", element: <Orders orderDetails /> },
        { path: "external_item", element: <ExternallItem /> },
        { path: "wallet/:id", element: <Wallet /> },
        { path: "cart/:id", element: <Cart /> },
        { path: "promos", element: <Promos /> },
        { path: "referral_code", element: <ReferralCode /> },
        { path: "shipping_config", element: <ShippingsConfigs /> },
        { path: "reasons", element: <Reasons /> },
        { path: "banners", element: <Banner /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "expenses", element: <ExpensesList /> },
        { path: "expense_types", element: <ExpenseTypesList /> },
        { path: "agent_city_config", element: <Agents /> },
        { path: "image_upload_center", element: <ImageUploadCenter /> },
        { path: "E-Payments", element: <EPayments /> },
        { path: "contact_info", element: <ContactInfo /> },
        { path: "General_Settings", element: <GeneralSetting /> },
        { path: "addresses", element: <AddressesList /> },
        { path: "settings", element: <ProfileSettingsView /> },
        { path: "mobile_app_version", element: <MobileAppVersion /> },
        { path: "cart_reviews", element: <CartReview /> },
        { path: "agent_requests", element: <AgentRequests /> },
        { path: "pop_up_post", element: <PopUpPost /> },
        { path: "wallet_reports", element: <WalletReport /> },
        { path: "reports", element: <ReportNew /> },
        { path: "ticket/statistic", element: <Statistic /> },
        { path: "ticket/allTickets", element: <Tickets /> },
        { path: "ticket/TicketDetails/:id", element: <TicketDetails /> },
        { path: "ticket/ticketCategory", element: <TicketCategory /> },
        { path: "Department/DepartmentConfig", element: <Departments /> },
        { path: "Department/employee", element: <Employee /> },
        { path: "Dynamic_Auth", element: <Permissions /> },
        { path: "deals/promoted_items", element: <PromotedItems /> },
        { path: "rating_questions", element: <RatingQuestions /> },
        { path: "deals/deals", element: <Deals /> },
        { path: "home_items", element: <HomeItems /> },
        { path: "user_orders/:id", element: <UserOrders /> },
        { path: "faq", element: <FAQ /> },
        { path: "onboarding", element: <Onboarding /> },
        { path: "welcome_message", element: <WelcomeMessage /> },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "order_rating", element: <OrderRating /> },
        { path: "gifts/order/:id", element: <GiftOrders orderDetails /> },
        { path: "incomplete_carts", element: <UncompletedCarts /> },
        { path: "shipments", element: <Shipments /> },
        { path: "purchasing_bill", element: <PurchasingBill /> },
        { path: "go_ip", element: <GoIp /> },
        { path: "unknown_items", element: <UnknownItems /> },
        { path: "notifications", element: <Notification /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "login", element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: "404", element: <NotFound /> },
        {
          path: "/",
          element: token ? (
            <Navigate to="app/welcome_message" />
          ) : (
            <Navigate to="/login" />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];

  const content = useRoutes(route);

  return content;
};

export default Routes;

import { TextField, Grid, Stack, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import {
  openDialog,
  resetForm,
  create,
  update,
} from "src/actions/unknownItems";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UnknownItemsForm = () => {
  const dispatch = useDispatch();

  const _UnknownItems = useSelector((state) => state.unknownItems);
  const loading = useSelector((state) => state.unknownItems.reload);
  const searchBody = useSelector((state) => state.unknownItems.searchBody);
  const pageNo = useSelector((state) => state.unknownItems.pageNo);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
  } = useForm({
    defaultValues: _UnknownItems.form,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(attachmentsFiles.map((files) => files.file));
    setValue("attachment", attachmentsFiles.map((files) => files.file)[0], {
      shouldDirty: true,
    });
  }, [attachmentsFiles, setValue]);

  const isAdd = _UnknownItems.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openDialog());
  };

  useEffect(() => {
    reset({ ..._UnknownItems.form });
  }, [reset, _UnknownItems.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("note", data.note);
    formData.append("quantity", data.quantity);
    formData.append("price", data.price);
    if (isAdd) {
      formData.append("attachment", files[0]);
      if (files[0]) {
        dispatch(create(formData, pageNo + 1, searchBody))
          .then((x) => {
            console.log(x);
            reset({ ..._UnknownItems.form });
            dispatch(resetForm());
            handleDialog();
          })
          .catch(() => dispatch(showErrorSnackbar("Network error")));
      } else {
        formData.append("attachmentId", data.attachment);
        dispatch(create(formData, pageNo + 1, searchBody)).then((x) => {
          reset({ ..._UnknownItems.form });
          dispatch(resetForm());
          handleDialog();
        });
      }
    } else {
      if (files[0]) {
        formData.append("attachment", files[0]);
        dispatch(update(data.id, formData, pageNo + 1, searchBody))
          .then((x) => {
            reset({ ..._UnknownItems.form });
            dispatch(resetForm());
            handleDialog();
          })
          .catch(() => dispatch(showErrorSnackbar("Network error")));
      } else {
        formData.append("attachmentId", data.attachment);
        dispatch(update(data.id, formData, pageNo + 1, searchBody)).then(
          (x) => {
            reset({ ..._UnknownItems.form });
            dispatch(resetForm());
            handleDialog();
          }
        );
      }
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create Unknown Items" : "Update Unknown Items"}
      isOpen={_UnknownItems.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Name" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  size="small"
                  label="Price"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  size="small"
                  label="Quantity"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Note" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="attachment"
              control={control}
              render={({ field }) => (
                <FilePond
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  required={isAdd}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={_UnknownItems.form.attachmentUrl}
                width={300}
                sx={{ objectFit: "contain" }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default UnknownItemsForm;

import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import CustomizedSnackbars from "../../Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SaveIcon from "@mui/icons-material/Save";
import UserDataService from "../../../api/services/user.service";
import { user_roles } from "src/utils/common";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddresses } from "src/actions/addresses";
import { userInfo } from "src/actions/users";

export default function ProfileSettings() {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const userRole = user_roles.filter((role) => role.id === _UserInfo.role)[0]
    ?.name;

  const addresses = useSelector((state) => state.addresses);

  const dispatch = useDispatch();

  const [openSpin, setOpenSpin] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const [oldPassowrd, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [userDetails, setUserDetails] = useState(_UserInfo);

  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  useEffect(() => {
    setSelectedProvince({
      ...selectedProvince,
      cities: addresses.filter(
        (city) => city.id === _UserInfo.provinceId && city.cities
      )[0]?.cities,
    });
  }, [addresses]);

  const gender = [
    { id: 0, name: `Male` },
    { id: 1, name: `Female` },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleDateChange = (newValue) => {
    setUserDetails({ ...userDetails, birthDate: newValue });
  };

  const errorAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "error" });
  const successAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "success" });
  const warningAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "warning" });

  function updatePassword() {
    if (!oldPassowrd || !newPassword) warningAlert("All fields are required");
    else if (newPassword !== confirmPassword)
      warningAlert("Password and confirmation does not match!");
    else {
      setOpenSpin(true);
      UserDataService.changePassword({
        oldPassword: oldPassowrd,
        newPassword: newPassword,
      })
        .then((res) => {
          successAlert("Updated successfully!");
          // refreshTable();
          setOpenSpin(false);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) errorAlert("Token expire");
            //un-authorized ,Logout
            else if (err.response.status === 400)
              errorAlert(err.response.data.message);
          } else errorAlert("Network error");
          setOpenSpin(false);
        });
    }
  }

  function updateUserDetails() {
    UserDataService.update(userDetails)
      .then((res) => {
        successAlert("Updated successfully!");
        dispatch(userInfo());
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) errorAlert("Token expire");
          //un-authorized ,Logout
          else if (err.response.status === 400)
            errorAlert(err.response.data.message);
        } else errorAlert("Network error");
      });
  }

  useEffect(() => {
    dispatch(getAllAddresses());
  }, []);

  return (
    <form autoComplete="off" noValidate>
      <Card>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                size="small"
                fullWidth
                label="First name"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Second Name"
                name="secondName"
                value={userDetails.secondName}
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Last Name"
                name="lastName"
                value={userDetails.lastName}
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                size="small"
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={userDetails.phoneNumber}
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Email"
                name="email"
                value={userDetails.email}
                variant="outlined"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={gender}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Gender" />
                )}
                onChange={(event, value) => {
                  setUserDetails({
                    ...userDetails,
                    gender: value ? value.id : null,
                  });
                }}
                value={
                  gender.filter((x) => x.id == userDetails.gender)[0] ?? null
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="Birth date"
                  name="birthdate"
                  value={userDetails.birthDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField fullWidth size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={addresses}
                getOptionLabel={(option) =>
                  option.nameAR
                    ? `${option.nameAR} , ${option.nameUS}`
                    : "Not selected"
                }
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Provinces" />
                )}
                onChange={(event, value) => {
                  setSelectedProvince(
                    value ? value : { nameAR: "", nameUS: "", cities: [] }
                  );
                  setUserDetails({
                    ...userDetails,
                    provinceId: value ? value.id : null,
                    cityId: null,
                  });
                }}
                value={
                  addresses.filter((x) => x.id == userDetails.provinceId)[0] ??
                  null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={selectedProvince.cities || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option.nameAR} , ${option.nameUS}`
                    : "Not selected"
                }
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Cities" />
                )}
                onChange={(event, value) => {
                  setUserDetails({
                    ...userDetails,
                    cityId: value ? value.id : null,
                    addressId: value ? value.id : null,
                  });
                }}
                value={
                  addresses
                    .map((city) => city.cities)
                    .flat()
                    .filter((x) => x.id == userDetails.cityId)[0] ?? null
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                size="small"
                fullWidth
                label="Role"
                name="role"
                value={userRole}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => updateUserDetails()}
            startIcon={<SaveIcon />}
          >
            Save details
          </Button>
        </Box>
      </Card>

      <Card>
        <CustomizedSnackbars
          open={alert.open}
          type={alert.type}
          message={alert.message}
          onClose={() =>
            setAlert((prevState) => ({
              ...prevState,
              open: false,
            }))
          }
        />
        <CardHeader subheader="Update your account password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            size="small"
            fullWidth
            label="Old password"
            margin="normal"
            name="password"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            type="password"
            value={oldPassowrd}
            variant="outlined"
          />
          <TextField
            size="small"
            fullWidth
            label="New password"
            margin="normal"
            name="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            type="password"
            value={newPassword}
            variant="outlined"
          />
          <TextField
            size="small"
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            type="password"
            value={confirmPassword}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updatePassword();
            }}
            startIcon={<EditIcon />}
          >
            Update Password
          </Button>
        </Box>
      </Card>
    </form>
  );
}

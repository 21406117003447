import * as React from "react";
import {
  Card,
  Grid,
} from "@mui/material";
import {
  getDetails,
} from "src/actions/orders";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Details from "./details";
import OrderDetailsTable from "./table";

export default function OrderDetails() {
  const orderId = window.location.pathname.replace("/app/order/", "");

  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(getDetails(orderId))
  }, [refresh]);

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item lg={9.5} md={12} xl={9.5} xs={12} order={{ xs: 2 }}>
          <OrderDetailsTable refresh={() => setRefresh(!refresh)} />
        </Grid>
        <Grid item lg={2.5} md={12} xl={2.5} xs={12} order={{ lg: 2 }}>
          <Details refresh={() => setRefresh(!refresh)} />
        </Grid>
      </Grid>
    </Card>
  );
}

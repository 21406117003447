import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Typography,
  DialogContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  createNotification,
  resetNotifyAll,
  setNotifyAllDialog,
  updateNotification,
} from "src/actions/notification";
import { LoadingButton } from "@mui/lab";

const NotifyAllForm = () => {
  const _NotifyAll = useSelector((state) => state.notification);
  const searchFilter = useSelector((state) => state.notification.searchBody);
  const PageNo = useSelector((state) => state.notification.pageNo);
  const loading = useSelector((state) => state.notification.reload);

  const [confirm, setConfirm] = useState(false);
  const [formData, setFormData] = useState(false);

  const isAdd = _NotifyAll.notifyAllForm.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
    formState: { errors },
  } = useForm({
    defaultValues: _NotifyAll.notifyAllForm,
  });

  useEffect(() => {
    reset({ ..._NotifyAll.notifyAllForm });
  }, [_NotifyAll.notifyAllForm, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetNotifyAll());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setConfirm(true);
    setFormData(data);
    // if (isAdd) {
    //   dispatch(
    //     createNotification(
    //       {
    //         titleKU: data.titleKU,
    //         titleEn: data.titleEn,
    //         titleAr: data.titleAr,
    //         messageKu: data.messageKu,
    //         messageEn: data.messageEn,
    //         messageAr: data.messageAr,
    //         isIos: data.isIos,
    //         isAndroid: data.isAndroid,
    //       },
    //       PageNo,
    //       searchFilter
    //     )
    //   );
    // } else {
    //   dispatch(
    //     updateNotification(
    //       {
    //         id: data.id,
    //         titleKU: data.titleKU,
    //         titleEn: data.titleEn,
    //         titleAr: data.titleAr,
    //         messageKu: data.messageKu,
    //         messageEn: data.messageEn,
    //         messageAr: data.messageAr,
    //         isIos: data.isIos,
    //         isAndroid: data.isAndroid,
    //       },
    //       PageNo,
    //       searchFilter
    //     )
    //   );
    // }
  };

  const handleDialog = () => {
    dispatch(setNotifyAllDialog());
  };

  return (
    <Dialog
      open={_NotifyAll.notifyAllDialog}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h4" component="div">
          {isAdd ? "Notify All" : "Update Notify All"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title English"
                    fullWidth
                    error={!!errors.titleEn}
                    helperText={errors.titleEn ? errors.titleEn.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Arabic"
                    fullWidth
                    error={!!errors.titleAr}
                    helperText={errors.titleAr ? errors.titleAr.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleKU"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Kurdish"
                    fullWidth
                    error={!!errors.titleKU}
                    helperText={errors.titleKU ? errors.titleKU.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content English"
                    fullWidth
                    error={!!errors.contentEn}
                    helperText={
                      errors.contentEn ? errors.contentEn.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content Arabic"
                    fullWidth
                    error={!!errors.contentAr}
                    helperText={
                      errors.contentAr ? errors.contentAr.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentKu"
                control={control}
                rules={{
                  required: { value: true, message: "Required Failed" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content Kurdish"
                    fullWidth
                    error={!!errors.contentKu}
                    helperText={
                      errors.contentKu ? errors.contentKu.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="isIos"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="IOS"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="isAndroid"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Android"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {confirm ? (
          <>
            <LoadingButton
              variant="contained"
              onClick={() => {
                console.log(formData);
              }}
              color="primary"
              sx={{ width: "15%", mx: 2 }}
              loading={loading}
            >
              Yes
            </LoadingButton>
            <Button
              variant="contained"
              onClick={() => setConfirm(false)}
              color="secondary"
              sx={{ width: "15%", mx: 2 }}
            >
              No
            </Button>
          </>
        ) : (
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            loading={loading}
            sx={{ width: "30%", mx: 2 }}
          >
            {isAdd ? "Save" : "Update User Permissions"}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NotifyAllForm;

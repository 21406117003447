import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import moment from "moment";
import useCanShow from "src/components/CanShow";
import {
  deleteNotification,
  getAllNotifications,
  getNotificationById,
  saveNotificationSearchBody,
} from "src/actions/notification";

export default function NotificationTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("28-02");
  const deleteRole = useCanShow("28-03");

  const _NotificationData = useSelector((state) => state.faq.FAQData);
  const _FAQ = useSelector((state) => state.faq);
  const searchFilter = useSelector((state) => state.faq.searchBody);
  const loading = useSelector((state) => state.faq.reload);
  const PageNo = useSelector((state) => state.faq.pageNo);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(PageNo ?? 1);
  const [title, setTitle] = useState(searchFilter.title ?? "");
  const [content, setContent] = useState(searchFilter.content ?? "");
  const [searchBody, setSearchBody] = useState({
    type: 2,
    pageSize: 15,
  });

  // useEffect(() => {
  //   dispatch(getAllNotifications(page, searchFilter));
  // }, [searchFilter, page, dispatch]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // dispatch(savePageNo(newPage));
  };

  function deleteSelectedItem() {
    setDeleteLoading(true);
    dispatch(deleteNotification(_FAQ.form.id, page, searchFilter)).then(
      (res) => {
        setDeleteLoading(false);
        // dispatch(resetForm());
        setOpenDeleteDialog(false);
      }
    );
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "titleEn",
      headerName: "Title English",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.titleEn}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.titleEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "titleAr",
      headerName: "Title Arabic",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.titleAr}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.titleAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "titleKu",
      headerName: "Title Kurdish",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.titleAr}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.titleAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "contentEn",
      headerName: "Content English",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.contentEn}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.contentEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "contentAr",
      headerName: "Content Arabic",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.contentAr}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.contentAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "contentKu",
      headerName: "Content Kurdish",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.contentAr}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.contentAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MdEdit />}
          label="Edit"
          hidden={!editRole}
          showInMenu
          onClick={(e) => {
            dispatch(getNotificationById(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<MdDelete color="error" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            maxWidth="sm"
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              // dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={_FAQ.form.questionEn}
            loading={deleteLoading}
          ></CustomDialog>
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveNotificationSearchBody({
                      ...searchFilter,
                      title: title,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Content"
              variant="outlined"
              value={content}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveNotificationSearchBody({
                      ...searchFilter,
                      content: content,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={loading}
                onClick={() => {
                  dispatch(saveNotificationSearchBody({ ...searchBody }));
                  setPage(1);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={loading}
                onClick={() => {
                  setPage(1);
                  setTitle("");
                  setContent("");
                  dispatch(
                    saveNotificationSearchBody({
                      pageSize: 15,
                      title: "",
                      isActive: null,
                      type: 2,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    title: "",
                    isActive: null,
                    type: 2,
                  });
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                height: { md: "calc(100vh - 270px)", xs: 1000 },
                minWidth: 1050,
              }}
            >
              <DataGrid
                columns={columns}
                rows={
                  _NotificationData?.data.map((data, index) => {
                    return { ...data, index: index + 1 };
                  }) || []
                }
                loading={loading}
                disableSelectionOnClick
                rowHeight={200}
                pagination
                paginationMode="server"
                rowCount={_NotificationData?.rowCount}
                pageSize={10}
                page={page}
                onPageChange={(newPage) => handlePageChange(newPage)}
                rowsPerPageOptions={[10]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

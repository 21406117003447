import NotificationSegmentation from "../api/services/notificationSegmentation.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createNotificationSegmentation = (data) => async (dispatch) => {
  try {
    const res = await NotificationSegmentation.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

import { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Chip,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import Logo from "./Logo";
import NotificationMenu from "./NotificationMenu";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExchangeRate, updateExchangeRate } from "src/actions/exchangeRate";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { ClickAwayListener } from "@mui/material";
import { useNavigate } from "react-router";
import { MdOutlineChangeCircle } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import { changeServiceType } from "src/actions/users";
import { getPersonalNotifications } from "src/actions/notification";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const _PersonalNotifications = useSelector(
    (state) => state.notification.personalNotificationData
  );

  const [exchangeRateValue, setChangeRatevalue] = useState();
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [exchangeRate, setExchangeRate] = useState("");
  const [editExchaneRate, setEditExchangeRate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    dispatch(getExchangeRate())
      .then((res) => {
        if (isMounted) {
          setExchangeRate(res.data);
        }
      })
      .catch((err) => {});
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    dispatch(getPersonalNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = localStorage.getItem("navigatePath");
    if (path) {
      navigate(path);
      localStorage.removeItem("navigatePath");
    }
  }, [navigate]);

  const handleSwitchService = (e) => {
    dispatch(
      changeServiceType(_UserInfo.serviceType === "Global" ? 1 : 0)
    ).then(() => {
      localStorage.setItem(
        "viewType",
        _UserInfo.serviceType === "Global" ? "gifts" : "cross"
      );
      window.location.href = "/app/welcome_message";
    });
  };

  return (
    <AppBar elevation={0} {...rest} sx={{ backgroundColor: "primary" }}>
      <Toolbar>
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <IconButton onClick={() => navigate("/app/welcome_message")}>
            <Logo
              vertical={_UserInfo.serviceType === "Global" ? false : true}
              style={{ width: 200 }}
            />
          </IconButton>
          {process.env.REACT_APP_ENV === "QA" ? (
            <Chip
              label="QA"
              color="warning"
              sx={{
                color: "primary",
                fontSize: "25px",
              }}
            />
          ) : null}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Exchange rate">
            {editExchaneRate &&
            _UserInfo.roleOEndpoint
              .map((data) => data.Code)
              .includes("10-02") ? (
              <ClickAwayListener
                onClickAway={() => {
                  setEditExchangeRate(false);
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  placeholder={exchangeRate}
                  variant="outlined"
                  sx={{ input: { color: "white" } }}
                  color="info"
                  focused
                  onChange={(e) => {
                    setChangeRatevalue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (exchangeRateValue) {
                        dispatch(updateExchangeRate(exchangeRateValue))
                          .then(() => {
                            setEditExchangeRate(false);
                            setLoading(!loading);
                          })
                          .catch((err) => {});
                      } else {
                        setEditExchangeRate(false);
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: true }}
                        onClick={() => {
                          if (exchangeRateValue) {
                            dispatch(updateExchangeRate(exchangeRateValue))
                              .then(() => {
                                setEditExchangeRate(false);
                                setLoading(!loading);
                              })
                              .catch((err) => {});
                          } else {
                            setEditExchangeRate(false);
                          }
                        }}
                      >
                        <CheckOutlinedIcon style={{ color: "white" }} />
                      </IconButton>
                    ),
                  }}
                />
              </ClickAwayListener>
            ) : (
              <div
                onDoubleClick={() => {
                  if (
                    _UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("10-02")
                  ) {
                    setEditExchangeRate(true);
                  }
                }}
              >
                <Typography>
                  EX. Rate {exchangeRate?.toLocaleString()} IQD
                </Typography>
              </div>
            )}
          </Tooltip>
          <RolePermission permission="31-18">
            <IconButton
              color="inherit"
              size="large"
              onClick={(e) => handleSwitchService()}
            >
              <MdOutlineChangeCircle />
            </IconButton>
          </RolePermission>
          <IconButton
            onClick={() => {
              setOpenNotificationMenu(true);
            }}
            color="inherit"
            size="large"
          >
            <Badge
              badgeContent={_PersonalNotifications?.length}
              color="error"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Stack>
        <NotificationMenu
          openNotification={openNotificationMenu}
          closeNotification={() => {
            setOpenNotificationMenu(false);
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;

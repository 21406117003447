import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as imageConversion from "image-conversion";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginImageCrop
);

const FilePondInput = ({
  required = false,
  allowMultiple = false,
  maxFiles,
  allowImagePreview,
  imagePreviewHeight,
  acceptedFileTypes,
  onupdatefiles,
  onBlur,
  files,
  name,
  imageTransformOutputMimeType = "image/jpeg",
  allowImageTransform = true,
  imageResizeTargetWidth = 800,
  imageResizeTargetHeight = 800,
  allowImageResize = true,
  allowImageCrop = true,
  imageTransformAfterCreateBlob,
}) => {
  return (
    <FilePond
      required={required}
      name={name}
      allowMultiple={allowMultiple}
      files={files}
      credits={false}
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      onupdatefiles={onupdatefiles}
      onBlur={onBlur}
      maxFiles={maxFiles}
      allowImagePreview={allowImagePreview}
      imagePreviewHeight={imagePreviewHeight}
      acceptedFileTypes={acceptedFileTypes}
      imageTransformOutputMimeType={imageTransformOutputMimeType}
      allowImageTransform={allowImageTransform}
      imageResizeTargetWidth={imageResizeTargetWidth}
      imageResizeTargetHeight={imageResizeTargetHeight}
      allowImageResize={allowImageResize}
      allowImageCrop={allowImageCrop}
      imageTransformAfterCreateBlob={imageTransformAfterCreateBlob}
    />
  );
};

export default FilePondInput;

import React, { useState, useEffect, useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Icon,
  Typography,
  LinearProgress,
  Chip,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Autocomplete,
  Drawer,
  Divider,
  Button,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import {
  cancelOrder,
  getAllOrders,
  saveOrderPageNum,
  saveOrderSearchBody,
} from "src/actions/gifts/orders";
import { Search as SearchIcon } from "react-feather";
import { Close } from "@mui/icons-material";
import {
  country_codes,
  gift_order_statuses,
  gift_payment_type,
} from "src/utils/common";
import DateRangepicker from "src/components/DateRangePicker";
import { getAllAddresses } from "src/actions/addresses";
import TimelineDialog from "src/pages/gifts/orders/timeline/OrderTimeline";
import OrderStatusMenu from "src/pages/gifts/orders/menu/OrderStatus";
import { CustomDialog } from "src/components/CustomDialog";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import ReactCountryFlag from "react-country-flag";
import { FaArrowDown, FaArrowUp, FaFilter, FaRegCopy } from "react-icons/fa";
import { changeFiltersType } from "src/actions/users";
import useCanShow from "src/components/CanShow";
import { VscNote } from "react-icons/vsc";

export default function OrdersTable() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const orderDetailsRole = useCanShow("47-02");
  const orderTimelineRole = useCanShow("47-09");

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const savePageNO = useSelector((state) => state.giftOrder.savePageNum);
  const orders = useSelector((state) => state.giftOrder.giftOrdersData.data);
  const reload = useSelector((state) => state.giftOrder.reload);
  const rowCount = useSelector(
    (state) => state.giftOrder.giftOrdersData.rowCount
  );
  const _searchBody = useSelector((state) => state.giftOrder.searchBody);
  const filterType = useSelector((state) => state.users.filterType);

  const [refresh, setRefresh] = useState(false);
  const [searchBody, setSearchBody] = useState(_searchBody);
  const [page, setPage] = useState(savePageNO ? savePageNO : 0);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  const activeFilters = useMemo(() => {
    if (
      searchBody.sku === "" &&
      searchBody.promoCodeName === "" &&
      searchBody.giftOrderNumber === "" &&
      searchBody.giftItemName === "" &&
      searchBody.sender === "" &&
      searchBody.receiver === "" &&
      searchBody.hasAdditionalFees === null &&
      searchBody.hasRemainingAmount === null &&
      searchBody.fromDeliveryDate === null &&
      searchBody.toDeliveryDate === null &&
      searchBody.fromCreatedAt === null &&
      searchBody.toCreatedAt === null &&
      searchBody.isRated === null &&
      searchBody.paymentType === null &&
      searchBody.status === null
    ) {
      return 0;
    } else {
      const truthyValuesCount = {
        sku: searchBody.sku.length > 0 ? 1 : 0,
        promoCodeName: searchBody.promoCodeName.length > 0 ? 1 : 0,
        giftItemName: searchBody.giftItemName.length > 0 ? 1 : 0,
        giftOrderNumber: searchBody.giftOrderNumber.length > 0 ? 1 : 0,
        sender: searchBody.sender.length > 0 ? 1 : 0,
        receiver: searchBody.receiver.length > 0 ? 1 : 0,
        hasRemainingAmount: searchBody.hasRemainingAmount !== null ? 1 : 0,
        hasAdditionalFees: searchBody.hasAdditionalFees !== null ? 1 : 0,
        fromDeliveryDate: searchBody.fromDeliveryDate !== null ? 1 : 0,
        toDeliveryDate: searchBody.toDeliveryDate !== null ? 1 : 0,
        fromCreatedAt: searchBody.fromCreatedAt !== null ? 1 : 0,
        toCreatedAt: searchBody.toCreatedAt !== null ? 1 : 0,
        isRated: searchBody.isRated !== null ? 1 : 0,
        paymentType: searchBody.paymentType !== null ? 1 : 0,
        status: searchBody.status !== null ? 1 : 0,
      };
      const activeFilters = Object.values(truthyValuesCount).filter((item) =>
        item === 1 ? true : false
      )?.length;
      return activeFilters;
    }
  }, [searchBody]);

  const isRated = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const hasRemainingAmount = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const hasAdditionalFees = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const initialOrderDetails = {
    id: null,
    giftOrderNumber: "",
    destination: "",
    quantity: "",
    status: "",
    toBeDelivered: "",
    totalAmount: "",
    createdAt: "",
  };
  const [order, setOrder] = useState(initialOrderDetails);

  useEffect(() => {
    dispatch(getAllOrders(page + 1, _searchBody)).then((res) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchBody, dispatch, refresh]);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(saveOrderPageNum(page));
  }, [dispatch, page]);

  const orderToolbar = () => {
    return (
      <Box>
        <TimelineDialog
          open={openTimelineDialog}
          handleClose={() => setOpenTimelineDialog(false)}
          orderId={order.id}
        />
      </Box>
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      width: 5,
    },
    {
      field: "number",
      headerName: "Number",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={(e) => {
            navigator.clipboard.writeText(params.row.number);
            dispatch(showSuccessSnackbar("Order Number Copied"));
          }}
        >
          <FaRegCopy />{" "}
          <span style={{ whiteSpace: "break-spaces", color: "#4C5C76" }}>
            {params.row.number}
          </span>
        </Stack>
      ),
    },
    {
      field: "user",
      headerName: "Sender Info",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="body" sx={{ whiteSpace: "break-spaces" }}>
            {params.row.user?.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <ReactCountryFlag
              style={{
                width: "40px",
                height: "30px",
                border: "solid 1px #dedede",
              }}
              svg
              countryCode={
                country_codes.filter(
                  (code) => code.dial_code === params.row.user?.countryCode
                )[0]?.code
              }
            />
            <Typography variant="body">
              {params.row.user?.phoneNumber}
            </Typography>
          </Stack>
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.receiverName}, ${params.row.phoneNumber}`,
    },
    {
      field: "customerFullName",
      headerName: "Recipient Info",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="body" sx={{ whiteSpace: "break-spaces" }}>
            {params.row.receiverName}
          </Typography>
          <Typography variant="body" sx={{ whiteSpace: "break-spaces" }}>
            {params.row.phoneNumber}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.receiverName}, ${params.row.phoneNumber}`,
    },
    {
      field: "receiverAddress",
      headerName: "Recipient address",
      flex: 1,
      renderCell: (params) => {
        const addressParts = [
          params.row.receiverAddress?.city?.province?.nameUS,
          params.row.receiverAddress?.city?.nameUS,
          params.row.receiverAddress?.neighborhood,
          params.row.receiverAddress?.nearestLocation,
        ].filter((part) => part !== null && part !== "");

        const formattedAddress = addressParts.join(", ");
        return (
          <Tooltip
            title={
              <Typography variant="subtitle1">{formattedAddress}</Typography>
            }
            followCursor
          >
            <Typography variant="body" sx={{ whiteSpace: "break-spaces" }}>
              {formattedAddress}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => {
        const createdAt = moment(params.row.createdAt).startOf("day");
        const now = moment().startOf("day");
        if (createdAt.isSame(now, "day")) {
          return <Chip color="success" label={"Today"} size="small" />;
        } else {
          return (
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="body">
                {moment(params.row.createdAt).format("YYYY-MM-DD")}
              </Typography>
              <Typography variant="body">
                {moment(params.row.createdAt).format("hh:mm a")}
              </Typography>
            </Stack>
          );
        }
      },
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => {
        const deliveryDate = moment(params.row.deliveryDate).startOf("day");
        const now = moment().startOf("day");
        if (deliveryDate.isSame(now, "day")) {
          return <Chip color="success" label={"Today"} size="small" />;
        } else {
          return (
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="body">
                {moment(params.row.deliveryDate).format("YYYY-MM-DD")}
              </Typography>
              <Typography variant="body">
                {moment(params.row.deliveryDate).format("hh:mm a")}
              </Typography>
            </Stack>
          );
        }
      },
    },
    {
      field: "totalAmountAfterDiscount",
      headerName: "Total Amount",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center">
          <Typography
            color="green"
            variant="body"
            sx={{ whiteSpace: "break-spaces" }}
          >
            {params.row.totalAmountAfterDiscount.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "isFastDelivery",
      headerName: "Fast Delivery",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) =>
        params.row.isFastDelivery ? (
          <Chip color="success" label={"Fast"} size="small" />
        ) : (
          <Chip color="secondary" label={"Normal"} size="small" />
        ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <OrderStatusMenu
            orderId={params.row.id}
            status={params.row.status}
            statusNumber={params.row.statusNumber}
            onSuccess={() => setRefresh(!refresh)}
            onCancel={() => {
              setOpenConfirmDialog(true);
              setOrder({ ...params.row, id: params.row.id });
            }}
          />
          {params.row.cancelReasonId ? (
            <IconButton>
              <Tooltip title={params.row.cancelReasonEN} aria-label="cancel">
                <InfoIcon color="error" />
              </Tooltip>
            </IconButton>
          ) : null}
        </Box>
      ),
    },
    {
      field: "emploteeNotes",
      headerName: "Notes",
      headerAlign: "center",
      flex: 0.3,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Notes
        </Typography>
      ),
      renderCell: (params) =>
        params.row.emploteeNotes && (
          <Tooltip
            title={
              <Typography variant="h5">{params.row.emploteeNotes}</Typography>
            }
            followCursor
          >
            <Typography variant="body" color="green">
              <VscNote size={30} />
            </Typography>
          </Tooltip>
        ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Order Details"
          hidden={!orderDetailsRole}
          showInMenu
          onClick={(e) => {
            navigate(`/app/gifts/order/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon color="warning">show_chart</Icon>}
          label="Order Timeline"
          hidden={!orderTimelineRole}
          showInMenu
          onClick={(e) => {
            setOrder({ ...order, id: params.row.id });
            setOpenTimelineDialog(true);
          }}
        />,
        <GridActionsCellItem
          icon={<WhatsAppIcon color="success" />}
          label="Chat via Whatsapp"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://wa.me/${
                (params.row.user?.countryCode ?? "+964") +
                params.row.user?.phoneNumber
              }`,
              "_blank"
            );
          }}
        />,
        <GridActionsCellItem
          icon={<Icon sx={{ color: "#229ED9" }}>telegram</Icon>}
          label="Chat via Telegram"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://t.me/${
                (params.row.user?.countryCode ?? "+964") +
                params.row.user?.phoneNumber
              }`,
              "_blank"
            );
          }}
        />,
      ],
    },
  ];

  function CustomToolbar() {
    return (
      _UserInfo.roles.includes("Export") && (
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </GridToolbarContainer>
      )
    );
  }

  return (
    <Card sx={{ p: 2 }}>
      {orderToolbar()}
      <CustomDialog
        isOpen={openConfirmDialog}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        isActiveDelete
        isRequierdActions
        title="Delete confirmation"
        maxWidth="sm"
        isConfirm
        isConfirmation
        confirmTitle="cancel"
        onCancel={() =>
          dispatch(cancelOrder(order?.id, true, page + 1, _searchBody)).then(
            () => setOpenConfirmDialog(false)
          )
        }
        selectedObject={`${order?.number}`}
      ></CustomDialog>
      <Drawer open={openSearch} onClose={toggleDrawer(false)}>
        <Divider />
        <Grid container spacing={2} padding={2} sx={{ width: 500 }}>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search Promo Code"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchBody.promoCodeName}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  promoCodeName: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveOrderSearchBody({
                      ...searchBody,
                      promoCodeName: searchBody.promoCodeName,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Recipient name or phone number"
              variant="outlined"
              value={searchBody.receiver}
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                var receiver = e.target.value;
                setSearchBody({
                  ...searchBody,
                  receiver:
                    receiver[0] === "0" ? receiver.substring(1) : receiver,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveOrderSearchBody({
                      ...searchBody,
                      receiver: searchBody.receiver,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Sender name or phone number"
              variant="outlined"
              value={searchBody.sender}
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                var sender = e.target.value;
                setSearchBody({
                  ...searchBody,
                  sender: sender[0] === "0" ? sender.substring(1) : sender,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveOrderSearchBody({
                      ...searchBody,
                      sender: searchBody.sender,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={2}>
          <Autocomplete
            options={addresses}
            getOptionLabel={(option) =>
              option.nameAR
                ? `${option.nameAR} , ${option.nameUS}`
                : "Not selected"
            }
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField required {...params} label="Provinces" />
            )}
            onChange={(event, value) => {
              setSelectedProvince(
                value ? value : { nameAR: "", nameUS: "", cities: [] }
              );
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  provinceId: value ? value.id : null,
                })
              );
            }}
            value={
              addresses.filter((x) => x.id === _searchBody.provinceId)[0] ??
              null
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={selectedProvince.cities}
            getOptionLabel={(option) =>
              option ? `${option.nameAR} , ${option.nameUS}` : "Not selected"
            }
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField required {...params} label="Cities" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  cityId: value ? value.id : null,
                })
              );
              setPage(0);
            }}
            value={
              addresses
                .map((city) => city.cities)
                .flat()
                .filter((x) => x.id === _searchBody.cityId)[0] ?? null
            }
          />
        </Grid> */}
          <Grid item xs={12}>
            <Autocomplete
              options={hasAdditionalFees}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Additional Fees" />
              )}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  hasAdditionalFees: value ? value.key : null,
                });
                setPage(0);
              }}
              value={
                hasAdditionalFees.filter(
                  (x) => x.key === searchBody.hasAdditionalFees
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={isRated}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Rated" />
              )}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  isRated: value ? value.key : null,
                });
                setPage(0);
              }}
              value={
                isRated.filter((x) => x.key === searchBody.isRated)[0] ?? null
              }
            />
          </Grid>
          {/* <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={showSenderName}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Show Sender Name !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  showSenderName: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              showSenderName.filter(
                (x) => x.key === _searchBody.showSenderName
              )[0] ?? null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={isFastDelivery}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Fast Delivery !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  isFastDelivery: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              isFastDelivery.filter(
                (x) => x.key === _searchBody.isFastDelivery
              )[0] ?? null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={askReceiver}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Ask Receiver !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  askReceiver: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              askReceiver.filter((x) => x.key === _searchBody.askReceiver)[0] ??
              null
            }
          />
        </Grid> */}
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={gift_order_statuses}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  status: value ? value.id : null,
                });
                setPage(0);
              }}
              value={
                gift_order_statuses.filter(
                  (x) => x.id === searchBody.status
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={gift_payment_type}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Payment Type" />
              )}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  paymentType: value ? value.id : null,
                });
                setPage(0);
              }}
              value={
                gift_payment_type.filter(
                  (x) => x.id === searchBody.paymentType
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={hasRemainingAmount}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Has Remaining Amount" />
              )}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  hasRemainingAmount: value ? value.key : null,
                });
                setPage(0);
              }}
              value={
                hasRemainingAmount.filter(
                  (x) => x.key === searchBody.hasRemainingAmount
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangepicker
              title="Delivery"
              disabled={reload}
              startDate={searchBody.fromDeliveryDate}
              endDate={searchBody.toDeliveryDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromDeliveryDate: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDeliveryDate: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromDeliveryDate !==
                    searchBody.fromDeliveryDate ||
                  updatedFilter.toDeliveryDate !== searchBody.toDeliveryDate
                ) {
                  setSearchBody({
                    ...searchBody,
                    fromDeliveryDate: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toDeliveryDate: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangepicker
              title="Created At"
              disabled={reload}
              startDate={searchBody.fromCreatedAt}
              endDate={searchBody.toCreatedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromCreatedAt: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toCreatedAt: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromCreatedAt !== searchBody.fromCreatedAt ||
                  updatedFilter.toCreatedAt !== searchBody.toCreatedAt
                ) {
                  setSearchBody({
                    ...searchBody,
                    fromCreatedAt: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toCreatedAt: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }
                setPage(0);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={reload}
              onClick={(e) => {
                dispatch(
                  saveOrderSearchBody({
                    ...searchBody,
                  })
                );
                setRefresh(!refresh);
                setPage(0);
              }}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={reload}
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => {
                setSearchBody({
                  pageSize: 15,
                  giftOrderNumber: "",
                  sku: "",
                  giftItemName: "",
                  promoCodeName: "",
                  sender: "",
                  receiver: "",
                  hasAdditionalFees: null,
                  isRated: null,
                  status: null,
                  paymentType: null,
                  hasRemainingAmount: null,
                  fromDeliveryDate: null,
                  toDeliveryDate: null,
                  fromCreatedAt: null,
                  toCreatedAt: null,
                });
                dispatch(
                  saveOrderSearchBody({
                    pageSize: 15,
                    giftOrderNumber: "",
                    sku: "",
                    giftItemName: "",
                    promoCodeName: "",
                    sender: "",
                    receiver: "",
                    hasAdditionalFees: null,
                    isRated: null,
                    status: null,
                    paymentType: null,
                    hasRemainingAmount: null,
                    fromDeliveryDate: null,
                    toDeliveryDate: null,
                    fromCreatedAt: null,
                    toCreatedAt: null,
                  })
                );
                setRefresh(!refresh);
              }}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={reload}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search Order"
            sx={{ marginRight: 1 }}
            variant="outlined"
            value={searchBody.giftOrderNumber}
            onChange={(e) => {
              setSearchBody({ ...searchBody, giftOrderNumber: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(
                  saveOrderSearchBody({
                    ...searchBody,
                    giftOrderNumber: searchBody.giftOrderNumber,
                  })
                );
                setPage(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={reload}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search Product SKU"
            sx={{ marginRight: 1 }}
            variant="outlined"
            value={searchBody.sku}
            onChange={(e) => {
              setSearchBody({ ...searchBody, sku: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(
                  saveOrderSearchBody({
                    ...searchBody,
                    sku: searchBody.sku,
                  })
                );
                setPage(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={reload}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search Product Name"
            sx={{ marginRight: 1 }}
            variant="outlined"
            value={searchBody.giftItemName}
            onChange={(e) => {
              setSearchBody({ ...searchBody, giftItemName: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(
                  saveOrderSearchBody({
                    ...searchBody,
                    giftItemName: searchBody.giftItemName,
                  })
                );
                setPage(0);
              }
            }}
          />
        </Grid>
        {filterType === "1" ? null : (
          <>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Promo Code"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={searchBody.promoCodeName}
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    promoCodeName: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveOrderSearchBody({
                        ...searchBody,
                        promoCodeName: searchBody.promoCodeName,
                      })
                    );
                    setPage(0);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DateRangepicker
                title="Delivery"
                disabled={reload}
                startDate={searchBody.fromDeliveryDate}
                endDate={searchBody.toDeliveryDate}
                handleOnChange={(searchDate) => {
                  const updatedFilter = {
                    fromDeliveryDate: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toDeliveryDate: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  if (
                    updatedFilter.fromDeliveryDate !==
                      searchBody.fromDeliveryDate ||
                    updatedFilter.toDeliveryDate !== searchBody.toDeliveryDate
                  ) {
                    setSearchBody({
                      ...searchBody,
                      fromDeliveryDate: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toDeliveryDate: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }
                  setPage(0);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Recipient name or phone number"
                variant="outlined"
                value={searchBody.receiver}
                sx={{ marginRight: 1 }}
                onChange={(e) => {
                  var receiver = e.target.value;
                  setSearchBody({
                    ...searchBody,
                    receiver:
                      receiver[0] === "0" ? receiver.substring(1) : receiver,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveOrderSearchBody({
                        ...searchBody,
                        receiver: searchBody.receiver,
                      })
                    );
                    setPage(0);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Sender name or phone number"
                variant="outlined"
                value={searchBody.sender}
                sx={{ marginRight: 1 }}
                onChange={(e) => {
                  var sender = e.target.value;
                  setSearchBody({
                    ...searchBody,
                    sender: sender[0] === "0" ? sender.substring(1) : sender,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveOrderSearchBody({
                        ...searchBody,
                        sender: searchBody.sender,
                      })
                    );
                    setPage(0);
                  }
                }}
              />
            </Grid>
            {/* <Grid item xs={12} md={2}>
          <Autocomplete
            options={addresses}
            getOptionLabel={(option) =>
              option.nameAR
                ? `${option.nameAR} , ${option.nameUS}`
                : "Not selected"
            }
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField required {...params} label="Provinces" />
            )}
            onChange={(event, value) => {
              setSelectedProvince(
                value ? value : { nameAR: "", nameUS: "", cities: [] }
              );
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  provinceId: value ? value.id : null,
                })
              );
            }}
            value={
              addresses.filter((x) => x.id === _searchBody.provinceId)[0] ??
              null
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={selectedProvince.cities}
            getOptionLabel={(option) =>
              option ? `${option.nameAR} , ${option.nameUS}` : "Not selected"
            }
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField required {...params} label="Cities" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  cityId: value ? value.id : null,
                })
              );
              setPage(0);
            }}
            value={
              addresses
                .map((city) => city.cities)
                .flat()
                .filter((x) => x.id === _searchBody.cityId)[0] ?? null
            }
          />
        </Grid> */}
            <Grid item xs={3}>
              <Autocomplete
                options={hasAdditionalFees}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Additional Fees" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    hasAdditionalFees: value ? value.key : null,
                  });
                  setPage(0);
                }}
                value={
                  hasAdditionalFees.filter(
                    (x) => x.key === searchBody.hasAdditionalFees
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DateRangepicker
                title="Created At"
                disabled={reload}
                startDate={searchBody.fromCreatedAt}
                endDate={searchBody.toCreatedAt}
                handleOnChange={(searchDate) => {
                  const updatedFilter = {
                    fromCreatedAt: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toCreatedAt: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  if (
                    updatedFilter.fromCreatedAt !== searchBody.fromCreatedAt ||
                    updatedFilter.toCreatedAt !== searchBody.toCreatedAt
                  ) {
                    setSearchBody({
                      ...searchBody,
                      fromCreatedAt: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toCreatedAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }
                  setPage(0);
                }}
              />
            </Grid>
            {/* <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={showSenderName}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Show Sender Name !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  showSenderName: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              showSenderName.filter(
                (x) => x.key === _searchBody.showSenderName
              )[0] ?? null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={isFastDelivery}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Fast Delivery !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  isFastDelivery: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              isFastDelivery.filter(
                (x) => x.key === _searchBody.isFastDelivery
              )[0] ?? null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            disabled={reload}
            options={askReceiver}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Ask Receiver !" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveOrderSearchBody({
                  ..._searchBody,
                  ...searchBody,
                  askReceiver: value ? value.key : null,
                })
              );
              setPage(0);
            }}
            value={
              askReceiver.filter((x) => x.key === _searchBody.askReceiver)[0] ??
              null
            }
          />
        </Grid> */}
            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={reload}
                options={gift_order_statuses}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Status" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    status: value ? value.id : null,
                  });
                  setPage(0);
                }}
                value={
                  gift_order_statuses.filter(
                    (x) => x.id === searchBody.status
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={reload}
                options={gift_payment_type}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    paymentType: value ? value.id : null,
                  });
                  setPage(0);
                }}
                value={
                  gift_payment_type.filter(
                    (x) => x.id === searchBody.paymentType
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={reload}
                options={hasRemainingAmount}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Has Remaining Amount" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    hasRemainingAmount: value ? value.key : null,
                  });
                  setPage(0);
                }}
                value={
                  hasRemainingAmount.filter(
                    (x) => x.key === searchBody.hasRemainingAmount
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disabled={reload}
                options={isRated}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Is Rated" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    isRated: value ? value.key : null,
                  });
                  setPage(0);
                }}
                value={
                  isRated.filter((x) => x.key === searchBody.isRated)[0] ?? null
                }
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={filterType === "1" ? 3 : 9}
          direction="row"
          container
          alignItems="start"
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" spacing={0}>
              {filterType === "1" ? (
                <IconButton
                  disabled={reload}
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                >
                  <Tooltip title="Advanced Search">
                    <Badge badgeContent={activeFilters} color="error">
                      <FaFilter />
                    </Badge>
                  </Tooltip>
                </IconButton>
              ) : null}
              <IconButton
                sx={{ marginLeft: 1 }}
                onClick={() => {
                  dispatch(
                    saveOrderSearchBody({ ..._searchBody, ...searchBody })
                  );
                }}
              >
                <Tooltip title="Search with filter" disabled={reload}>
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                sx={{ marginLeft: 1 }}
                onClick={() => {
                  dispatch(
                    saveOrderSearchBody({
                      pageSize: 15,
                      giftOrderNumber: "",
                      sku: "",
                      giftItemName: "",
                      promoCodeName: "",
                      sender: "",
                      receiver: "",
                      hasAdditionalFees: null,
                      isRated: null,
                      status: null,
                      paymentType: null,
                      hasRemainingAmount: null,
                      fromDeliveryDate: null,
                      toDeliveryDate: null,
                      fromCreatedAt: null,
                      toCreatedAt: null,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    giftOrderNumber: "",
                    sku: "",
                    giftItemName: "",
                    promoCodeName: "",
                    sender: "",
                    receiver: "",
                    hasAdditionalFees: null,
                    isRated: null,
                    status: null,
                    paymentType: null,
                    hasRemainingAmount: null,
                    fromDeliveryDate: null,
                    toDeliveryDate: null,
                    fromCreatedAt: null,
                    toCreatedAt: null,
                  });
                  setPage(0);
                }}
              >
                <Tooltip title="Clear filter" disabled={reload}>
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(changeFiltersType(filterType === "1" ? "0" : "1"));
              }}
            >
              {filterType === "1" ? (
                <FaArrowDown size={30} />
              ) : (
                <FaArrowUp size={30} />
              )}
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <PerfectScrollbar>
            <Box
              sx={{
                height: {
                  md:
                    filterType === "1"
                      ? "calc(100vh - 265px)"
                      : "calc(100vh - 431px)",
                  xs: 1000,
                },
              }}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                rows={
                  orders?.map((row, index) => {
                    return { ...row, sequence: index + 1 };
                  }) || []
                }
                loading={reload}
                columns={columns}
                rowHeight={80}
                disableSelectionOnClick
                pagination
                onPageChange={(newPage) => {
                  setPage(newPage);
                  setRefresh(!refresh);
                }}
                pageSize={_searchBody.pageSize}
                onPageSizeChange={(newPageSize) => {
                  dispatch(
                    saveOrderSearchBody({
                      ...searchBody,
                      pageSize: newPageSize,
                    })
                  );
                  setRefresh(!refresh);
                }}
                page={page}
                rowCount={rowCount ? rowCount : 0}
                paginationMode="server"
                rowsPerPageOptions={[15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
              />
            </Box>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Card>
  );
}

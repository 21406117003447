import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  TextField,
  Box,
  LinearProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../../CustomSpinner";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import TransferMoney from "./TransferMoney";
import { getById, update, getOrderiiBalance } from "src/actions/wallet";
import {
  wallet_action_types,
  Transaction_Status,
  country_codes,
} from "src/utils/common";
import { getAllTransaction } from "src/actions/transaction";
import TollIcon from "@mui/icons-material/Toll";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InfoIcon from "@mui/icons-material/Info";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import RolePermission from "src/components/RolePermission";
import DateRangepicker from "src/components/DateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import ReactCountryFlag from "react-country-flag";

export default function WalletDetails() {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const scrollToTop = useRef(null);

  const [rowCount, setRowCount] = useState();

  const [transactions, setTransactions] = useState(null);

  const [orderiiBalance, setOrderiiBalance] = useState({
    cash: 0,
    wallet: 0,
  });

  const [refresh, setRefresh] = useState(false);

  const [isLoading, setIsloading] = useState(false);
  const [reload, setReload] = useState(false);
  const [openChangeWalletAmount, setOpenChangeWalletAmount] = useState(false);
  const [actionType, setActionType] = useState("");

  const userId = window.location.pathname.replace("/app/wallet/", "");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [transactionFilter, setTransactionFilter] = useState({
    startDate: moment("2020-01-01").format("yyyy-MM-DD 00:00:00"),
    endDate: null,
    transactionType: null,
    transactionStatus: null,
  });

  useEffect(() => {
    setReload(true);
    dispatch(
      getAllTransaction(userId, page + 1, { ...transactionFilter, pageSize })
    ).then((res) => {
      setTransactions(res.data);
      setRowCount(res.rowCount);
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, pageSize]);

  useEffect(() => {
    if (_UserInfo.roleOEndpoint.map((data) => data.Code).includes("34-10")) {
      dispatch(getOrderiiBalance()).then((res) => {
        setOrderiiBalance(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getWalletByUserId(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  function getWalletByUserId(userId) {
    dispatch(getById(userId))
      .then((res) => {
        setWallet(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401)
            dispatch(showErrorSnackbar("Token expire"));
          //un-authorized ,Logout
          else if (err.response.status === 400)
            dispatch(showErrorSnackbar(err.response.data.message));
        } else dispatch(showErrorSnackbar("Network error"));
      });
  }

  const [wallet, setWallet] = useState({
    address: "null",
    balance: 0,
    id: null,
    name: "",
    phoneNumber: "",
    points: 0,
    walletType: 0,
  });

  const dispatch = useDispatch();

  function updateWallet() {
    setIsloading(true);
    dispatch(
      update({
        walletId: wallet.id,
        type: wallet.walletType,
      })
    )
      .then((res) => {
        dispatch(showSuccessSnackbar("Type updated successfully"));
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
      });
  }

  function CustomToolbar() {
    return (
      <Box>
        <GridToolbarContainer>
          <GridToolbarExport
            printOptions={{
              disableToolbarButton: true,
              hideFooter: true,
              hideToolbar: true,
            }}
            csvOptions={{
              fileName: "Transactions || Orderii",
              utf8WithBom: true,
              allColumns: true,
            }}
          />
        </GridToolbarContainer>
      </Box>
    );
  }

  const columns = [
    {
      field: "transactionNumber",
      headerName: "T-Number",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "amount",
      headerName: "Amount ($)",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "from",
      headerName: "From",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          {params.row.from === null && params.row.type === 1 ? (
            <Typography color="primary">Orderii Wallet</Typography>
          ) : (
            <>
              <Typography
                sx={{ fontSize: params.row.from.name.length > 18 ? 14 : null }}
              >
                {params.row.from ? params.row.from.name : null}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactCountryFlag
                  style={{
                    width: "40px",
                    height: "30px",
                    border: "solid 1px #dedede",
                  }}
                  svg
                  countryCode={
                    country_codes.filter(
                      (code) => code.dial_code === params.row.countryCode
                    )[0]?.code
                  }
                />
                <Typography variant="body">{params.row.phoneNumber}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.from?.name || ""} - ${
          `(${params.row.from?.countryCode ?? ""}) ` +
            params.row.from?.phoneNumber || ""
        }`,
    },
    {
      field: "to",
      headerName: "To",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          {params.row.to ? (
            <>
              <Typography
                sx={{ fontSize: params.row.to.name.length > 18 ? 14 : null }}
              >
                {params.row.to.name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactCountryFlag
                  style={{
                    width: "40px",
                    height: "30px",
                    border: "solid 1px #dedede",
                  }}
                  svg
                  countryCode={
                    country_codes.filter(
                      (code) => code.dial_code === params.row.to?.countryCode
                    )[0]?.code
                  }
                />
                <Typography variant="body">
                  {params.row.to?.phoneNumber}
                </Typography>
              </Stack>
            </>
          ) : null}
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.to?.name || ""} - ${params.row.to?.phoneNumber || ""}`,
    },
    {
      field: "fromWallet",
      headerName: "From User Balance ($)",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 14 }}>
            {params.row.fromOldBalance !== null &&
            params.row.fromOldBalance !== undefined
              ? `Old: ${params.row.fromOldBalance}`
              : ""}{" "}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {params.row.fromNewBalance !== null &&
            params.row.fromNewBalance !== undefined
              ? `New: ${params.row.fromNewBalance}`
              : ""}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) =>
        `${`Old: ${params.row.fromOldBalance ?? 0}`} - ${`New: ${
          params.row.fromNewBalance ?? 0
        }`}`,
    },
    {
      field: "toWallet",
      headerName: "To User Balance ($)",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 14 }}>
            {params.row.toOldBalance !== null &&
            params.row.toOldBalance !== undefined
              ? `Old: ${params.row.toOldBalance}`
              : ""}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {params.row.toNewBalance !== null &&
            params.row.toNewBalance !== undefined
              ? `New: ${params.row.toNewBalance}`
              : ""}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) =>
        `${`Old: ${params.row.toOldBalance ?? 0}`} - ${`New: ${
          params.row.toNewBalance ?? 0
        }`}`,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      flex: 0.7,
      renderCell: (params) => (
        <Box>
          {params.row.createdAt ? (
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Typography>
                {moment(params.row.createdAt).format("yyy-MM-DD")}
              </Typography>
              <Typography>
                {moment(params.row.createdAt).format("hh:mm a")}
              </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      flex: 0.6,
      renderCell: (params) => (
        <Box>
          {params.row.type === 1 ? (
            <Typography color="green">DEPOSIT</Typography>
          ) : params.row.type === 2 ? (
            <Typography color="red">WITHDRAW</Typography>
          ) : null}
        </Box>
      ),
      valueGetter: (params) =>
        `${
          params.row.type === 1
            ? "DEPOSIT"
            : params.row.type === 2
            ? "WITHDRAW"
            : ""
        }`,
    },
    {
      field: "exchangeRate",
      headerName: "Exchange Rate ($)",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Typography>{params.row.exchangeRate?.toLocaleString()}</Typography>
      ),
    },
    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ whiteSpace: "break-spaces" }}>
              {params.row.reasonString ? params.row.reasonString : ""}
            </Typography>
            <Typography>
              {params.row.ePaymentTypeString
                ? params.row.ePaymentTypeString
                : null}
            </Typography>
          </Stack>
          {params.row.info !== null ? (
            <Tooltip
              title={
                <Typography>
                  {params.row.info ? params.row.info : null}
                </Typography>
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.note ? (
            <Tooltip
              title={
                <Typography>
                  {params.row.note ? params.row.note : null}
                </Typography>
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.orderId !== null ? (
            <Tooltip title="order details">
              <IconButton
                href={`/app/order/${
                  params.row.orderId ? params.row.orderId : ""
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <FactCheckIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : params.row.giftOrderId !== null ? (
            <Tooltip title="order details">
              <IconButton
                href={`/app/gifts/order/${
                  params.row.giftOrderId ? params.row.giftOrderId : ""
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <FactCheckIcon sx={{ color: "orange" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      ),
      valueGetter: (params) => params.row.reasonString,
    },
    {
      field: "statusString",
      headerName: "Status",
      headerAlign: "center",
      flex: 0.8,
    },
  ];

  return (
    <Card ref={scrollToTop}>
      <RolePermission permission="34-10">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="#6b6c6d"
          color={"white"}
        >
          <Stack
            direction="row"
            spacing={5}
            sx={{ margin: "auto", marginY: 2 }}
          >
            <Typography variant="h4">
              Wallet:{" "}
              {orderiiBalance.wallet.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              $
            </Typography>
            <Typography variant="h4">|</Typography>
            <Typography variant="h4">
              Cash:{" "}
              {orderiiBalance.cash.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "}
              $
            </Typography>
          </Stack>
        </Grid>
      </RolePermission>

      <TransferMoney
        open={openChangeWalletAmount}
        handleClose={() => setOpenChangeWalletAmount(false)}
        userName={wallet.name}
        userPhoneNumber={wallet.phoneNumber}
        userBalance={wallet.balance}
        type={actionType}
        onSuccess={(msg) => {
          setRefresh(!refresh);
        }}
      />

      <Box
        sx={{
          padding: 2.5,
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h4">{wallet.name}</Typography>
            <Typography variant="h5">
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactCountryFlag
                  style={{
                    width: "40px",
                    height: "30px",
                    border: "solid 1px #dedede",
                  }}
                  svg
                  countryCode={
                    country_codes.filter(
                      (code) => code.dial_code === wallet.countryCode
                    )[0]?.code
                  }
                />
                <Typography variant="body">{wallet.phoneNumber}</Typography>
              </Stack>
              {wallet.phoneNumber}
            </Typography>
            <Typography variant="h5">{wallet.address}</Typography>
            <Box sx={{ marginTop: 4 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Wallet type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={wallet.walletType}
                  onChange={(e) =>
                    setWallet({ ...wallet, walletType: e.target.value })
                  }
                >
                  <FormControlLabel
                    disabled={
                      _UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("34-14")
                        ? false
                        : true
                    }
                    value={0}
                    control={<Radio />}
                    label="Customer" //0
                  />
                  <FormControlLabel
                    disabled={
                      _UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("34-14")
                        ? false
                        : true
                    }
                    value={1}
                    control={<Radio />}
                    label="Representative" //1
                  />
                  <RolePermission permission="34-14">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      onClick={updateWallet}
                      loading={isLoading}
                    >
                      Update
                    </LoadingButton>
                  </RolePermission>
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={1} sx={{ float: "right" }}>
              <Stack direction={"column"} spacing={1}>
                <Box
                  sx={{
                    margin: "auto",
                    padding: 2.5,
                    border: "solid",
                    borderRadius: 3,
                    color: "#1abbe8",
                    backgroundColor: "#1abbe8",
                    width: "180px",
                    height: "100%",
                    display: "flex",
                  }}
                >
                  <Tooltip id="1" title="Balance">
                    <MonetizationOnIcon style={{ color: "white" }} />
                  </Tooltip>
                  <Typography marginLeft={0.5} variant="h4" color="white">
                    {`${wallet.balance}$`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    margin: "auto",
                    padding: 2.5,
                    border: "solid",
                    borderRadius: 3,
                    color: "#939495",
                    backgroundColor: "#939495",
                    height: "100%",
                    width: "180px",
                    display: "flex",
                  }}
                >
                  <Tooltip title="Points">
                    <TollIcon style={{ color: "white" }} />
                  </Tooltip>
                  <Typography marginLeft={0.5} variant="h4" color="white">
                    {`${wallet.points}`}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction={"column"} spacing={1}>
                <RolePermission permission="34-01">
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setActionType(wallet_action_types.transferMoney);
                      setOpenChangeWalletAmount(true);
                    }}
                  >
                    Transfer Money
                  </Button>
                </RolePermission>
                <RolePermission permission="34-08">
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setActionType(wallet_action_types.clearance);
                      setOpenChangeWalletAmount(true);
                    }}
                  >
                    clearance
                  </Button>
                </RolePermission>
                <RolePermission permission="34-07">
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setActionType(wallet_action_types.TopUp);
                      setOpenChangeWalletAmount(true);
                    }}
                  >
                    Top-up
                  </Button>
                </RolePermission>
                <RolePermission permission="34-09">
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setActionType(wallet_action_types.refund);
                      setOpenChangeWalletAmount(true);
                    }}
                  >
                    Refund
                  </Button>
                </RolePermission>
                <RolePermission permission="34-02">
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setActionType(wallet_action_types.withdraw);
                      setOpenChangeWalletAmount(true);
                    }}
                  >
                    Withdraw
                  </Button>
                </RolePermission>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Stack
        sx={{ padding: 2 }}
        direction="row"
        justifyContent="start"
        alignItems="center"
        spacing={2}
      >
        {/* <Typography>Search filter:</Typography> */}
        {/* <DateRangePicker
          placement="auto"
          placeholder="Select Date Range"
          size="lg"
          onChange={(range) => {
            range
              ? setTransactionFilter({
                  ...transactionFilter,
                  startDate: moment(range[0].setHours(0, 0, 0)).format(
                    "yyyy-MM-DD HH:mm:ss"
                  ),
                  endDate: moment(range[1].setHours(23, 59, 59)).format(
                    "yyyy-MM-DD HH:mm:ss"
                  ),
                })
              : setTransactionFilter({
                  ...transactionFilter,
                  startDate: null,
                  endDate: null,
                });
            setRefresh(!refresh);
          }}
        /> */}
        <DateRangepicker
          startDate={transactionFilter.startDate}
          endDate={transactionFilter.endDate}
          handleOnChange={(searchDate) => {
            const updatedFilter = {
              fromDate: moment(searchDate[0].startDate).format(
                "YYYY-MM-DD 00:00:00"
              ),
              toDate: moment(searchDate[0].endDate).format(
                "YYYY-MM-DD 23:59:59"
              ),
            };
            if (
              updatedFilter.fromDate !== transactionFilter.startDate ||
              updatedFilter.toDate !== transactionFilter.endDate
            ) {
              setTransactionFilter({
                ...transactionFilter,
                startDate: moment(searchDate[0].startDate).format(
                  "YYYY-MM-DD 00:00:00"
                ),
                endDate: moment(searchDate[0].endDate).format(
                  "YYYY-MM-DD 23:59:59"
                ),
              });
              setRefresh(!refresh);
            }
            setPage(0);
          }}
        />
        <Box width={200}>
          <Autocomplete
            options={["Deposit", "Withdraw"]}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Transaction Type" />
            )}
            onChange={(event, value) => {
              setTransactionFilter({
                ...transactionFilter,
                transactionType: value ?? null,
              });
              setRefresh(!refresh);
            }}
            value={transactionFilter.transactionType}
          />
        </Box>
        <Box width={200}>
          <Autocomplete
            options={Transaction_Status}
            getOptionLabel={(option) => option.name}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Transaction Status" />
            )}
            onChange={(event, value) => {
              setTransactionFilter({
                ...transactionFilter,
                transactionStatus: value ? value.id : null,
              });
              setRefresh(!refresh);
            }}
            value={
              Transaction_Status.filter(
                (x) => x.id === transactionFilter.transactionStatus
              )[0] ?? null
            }
          />
        </Box>
        <Tooltip title="Clear filter">
          <IconButton
            onClick={() => {
              setTransactionFilter({
                startDate: moment("2020-01-01").format("yyyy-MM-DD 00:00:00"),
                endDate: null,
                transactionType: null,
                transactionStatus: null,
              });
              setRefresh(!refresh);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <PerfectScrollbar>
        <Box>
          {reload || !transactions ? (
            <CustomSpinner />
          ) : (
            <Box>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                autoHeight
                rows={transactions}
                columns={columns}
                loading={reload}
                rowHeight={90}
                disableSelectionOnClick
                pagination
                onPageChange={(newPage) => {
                  setPage(newPage);
                  setRefresh(!refresh);
                }}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                pageSize={pageSize}
                page={page}
                rowCount={rowCount ? rowCount : 0}
                paginationMode="server"
                rowsPerPageOptions={[20, 50, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  // NoRowsOverlay: CustomEmpty,
                  Toolbar: CustomToolbar,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

import * as React from "react";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Divider,
  Tooltip,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";
import moment from "moment";
import { updateLockerNumber } from "src/actions/orders";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { useState, useEffect } from "react";
import { RiVipFill } from "react-icons/ri";
import { RiVipCrownFill } from "react-icons/ri";
import { GiLockers } from "react-icons/gi";
import CustomText from "src/components/CustomText";
import { CustomDialog } from "src/components/CustomDialog";
import OrderStatusMenu from "../../menu/OrderStatus";
import TimelineDialog from "../../timeline/OrderTimeline";
import GenerateInvoice from "../../generate/generateInvoice";
import CancelDialog from "../../CancelDialog/CancelDialog";
import RolePermission from "src/components/RolePermission";
import GenerateLabel from "src/pages/cross/orders/generate/generateLabel";
import ReactStars from "react-rating-stars-component";
import OrderMenu from "src/pages/cross/orders/orderDetails/details/orderActions";

export default function Details(props) {
  const order = useSelector((state) => state.orders.orderDetails);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [isOrder, setIsOrder] = useState(null);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openCancelReasonsDialog, setOpenCancelReasonsDialog] = useState(false);
  const [lockerNumber, setLockerNumber] = useState("");
  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [ratingData, setRatingData] = useState({});

  useEffect(() => setLockerNumber(order.lockerNumber ?? ""), [order]);

  const orderId = window.location.pathname.replace("/app/order/", "");

  const dispatch = useDispatch();

  // function handleUpdateDelivery(data) {
  //   dispatch(updateDelivery(data, data.id));
  // }

  function handleLockerNumber() {
    dispatch(updateLockerNumber(orderId, lockerNumber));
  }

  return (
    <Box>
      <CustomDialog
        isOpen={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        title={"INVOICE"}
        maxWidth="md"
      >
        <GenerateInvoice data={order} />
      </CustomDialog>

      <CustomDialog
        isOpen={openLabelDialog}
        handleClose={() => setOpenLabelDialog(false)}
        title={"Label"}
        maxWidth="md"
      >
        <GenerateLabel data={order} />
      </CustomDialog>

      <CancelDialog
        open={openCancelReasonsDialog}
        handleClose={() => setOpenCancelReasonsDialog(false)}
        type={isOrder ? "order" : "item"}
        id={orderId}
        orderId={orderId}
        onError={(msg) => dispatch(showErrorSnackbar(msg))}
        onSuccess={(msg) => {
          dispatch(showSuccessSnackbar(msg));
          props.refresh();
        }}
      />
      <TimelineDialog
        open={openTimelineDialog}
        handleClose={() => setOpenTimelineDialog(false)}
        orderId={order.id}
      />
      <CustomDialog
        isOpen={openRatingDialog}
        handleClose={() => setOpenRatingDialog(false)}
        title="Order Rating"
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">Average:</Typography>
          <ReactStars
            edit={false}
            value={ratingData?.ratingAverage}
            size={40}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {ratingData?.rates?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="h4">
                {data?.ratingQuestion?.questionEn}
              </Typography>
              <ReactStars edit={false} value={data.rate} size={32} />
              {index !== ratingData?.rates?.length - 1 && <Divider />}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
      {order.items.length !== 0 ? (
        <Stack direction="column">
          <Card sx={{ padding: 2 }}>
            <Box>
              <Box display="flex" justifyContent="end">
                <OrderMenu
                  order={order}
                  _UserInfo={_UserInfo}
                  setOpenInvoiceDialog={setOpenInvoiceDialog}
                  setOpenLabelDialog={setOpenLabelDialog}
                  setOpenTimelineDialog={setOpenTimelineDialog}
                  setOpenRatingDialog={setOpenRatingDialog}
                  setRatingData={setRatingData}
                  onSuccess={(e) => props.refresh()}
                  onCancel={() => {
                    setIsOrder(true);
                    setOpenCancelReasonsDialog(true);
                  }}
                />
              </Box>
              <Stack
                sx={{ justifyContent: "space-between", alignItems: "center" }}
                direction={"row"}
                spacing={1}
              >
                <OrderStatusMenu
                  orderId={order.id}
                  status={order.status}
                  onSuccess={(e) => props.refresh()}
                  onCancel={() => {
                    setIsOrder(true);
                    setOpenCancelReasonsDialog(true);
                  }}
                />
                {order.isVipShipped && (
                  <Stack direction="column" spacing={-0.8}>
                    <RiVipCrownFill size={30} color="#D99B12" />
                    <RiVipFill size={30} color="#D99B12" />
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                <Typography variant="h4">{order.number ?? ""}</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h4">Total amount:</Typography>
                <Typography variant="h4">{order.totalAmount ?? 0}$</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Subtotal:</Typography>
                <Typography variant="h5">{order.subTotal ?? 0}$</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">IQ customs:</Typography>
                <Typography variant="h5">
                  {order.totalIQCustoms ?? 0}$
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Orderii Fee:</Typography>
                <Typography variant="h5">{order.orderiiFee ?? 0}$</Typography>
              </Stack>
              {order.aqsatiFee ? (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Aqsati Fee:</Typography>
                  <Typography variant="h5">{order.aqsatiFee ?? 0}$</Typography>
                </Stack>
              ) : null}
              {order.hasUSItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">US shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalUSShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasTRItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">TR shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalTRShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasAGItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">AG shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalAGShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasEUItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">EU shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalEUShiiping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasWSMItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">WSM shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalWSMShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">To be delivered:</Typography>
                <Stack direction="row" spacing={1}>
                  {order.toBeDelivered ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.deliveryCost}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Insurance:</Typography>
                <Stack direction="row" spacing={1}>
                  {order.isInsured ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.insuranceFee}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">VIP Shipping:</Typography>
                <Stack direction="column" spacing={1}>
                  {order.isVipShipped ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              {order.isVipShipped ? (
                <Box>
                  {order.hasUSItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip US Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipUSTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasTRItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip TR Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipTRTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasAGItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip AG Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipAGTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasEUItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip EU Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipEUTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasWSMItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip WSM Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipWSMTax}$
                      </Typography>
                    </Stack>
                  )}
                </Box>
              ) : null}
              {order.promoCodeId && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Promo Code:</Typography>
                  <Typography variant="h5">
                    {order.promoCodeName ?? ""}
                  </Typography>
                </Stack>
              )}
              {order.referralDiscount && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Referral Code:</Typography>
                  <Typography variant="h5">{order.referralCode}</Typography>
                  <Icon color="success">check_circle</Icon>
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Total discount:</Typography>
                <Typography variant="h5">
                  {order.totalDiscount ?? "0"}$
                </Typography>
              </Stack>
              {order.totalCashBack ? (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Total CashBack:</Typography>
                  <Typography variant="h5">
                    {order.totalCashBack ?? "0"}$
                  </Typography>
                </Stack>
              ) : null}
              {/* <RolePermission permission="22-07">
                <Typography variant="h5">
                  Delivery to home!
                  <Tooltip
                    title="(Deactive) | (Active)"
                    aria-label="active_deactive"
                  >
                    <Switch
                      checked={order.toBeDelivered}
                      onChange={(e) => {
                        handleUpdateDelivery({
                          id: order.id,
                          tobeDelivered: e.target.checked,
                        });
                      }}
                    />
                  </Tooltip>
                </Typography>
              </RolePermission> */}
              <Divider sx={{ margin: "5px 0" }}></Divider>
              <Typography variant="h5">
                Total Amount At Creation Date:{" "}
                <strong>{order.totalAmountAtCreationDate ?? "0"}$</strong>
              </Typography>
              <Divider sx={{ margin: "5px 0" }}></Divider>
              <RolePermission permission="22-14">
                <Box>
                  <CustomText
                    fullWidth
                    label="Locker"
                    name="lockerNumber"
                    value={lockerNumber}
                    onChange={(e) => {
                      setLockerNumber(e.target.value);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleLockerNumber() : null
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ marginY: 3 }}>
                          <GiLockers size={25} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginY: 2 }}>
                          <Button
                            variant="contained"
                            onClick={handleLockerNumber}
                          >
                            Update
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Divider sx={{ margin: 1 }}></Divider>
                </Box>
              </RolePermission>
            </Box>
            <Stack spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer name">
                  <Icon>account_circle</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.fullName : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer phone number">
                  <Icon>call</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.phoneNumber : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer address">
                  <Icon>pin_drop</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.address : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Reviewer name">
                  <Icon>assignment_ind</Icon>
                </Tooltip>
                <Typography variant="body1">{order.reviewer}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Order date">
                  <Icon>today</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {moment(order.createdAt).format("yyy-MM-DD / hh:mm a")}
                </Typography>
              </Stack>
              <Divider />

              {/* <Grid container spacing={1} width="100%">
                <RolePermission permission="58-01">
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="Order Additional Fees"
                      onClick={(e) => {
                        dispatch(openDialog("global"));
                      }}
                    >
                      <IconButton>
                        <HiCurrencyDollar />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </RolePermission>
                {_UserInfo.roles.includes("PrintLabelAndInvoice") ? (
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="Print Invoice"
                      onClick={() => setOpenInvoiceDialog(true)}
                    >
                      <IconButton>
                        <LocalPrintshopIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
                {_UserInfo.roles.includes("PrintLabelAndInvoice") ? (
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="Print Label"
                      onClick={() => setOpenLabelDialog(true)}
                    >
                      <IconButton>
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
                <RolePermission permission="22-03">
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="Timeline"
                      onClick={() => setOpenTimelineDialog(true)}
                    >
                      <IconButton>
                        <Icon>show_chart</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </RolePermission>
                <RolePermission permission="34-12">
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="Go to wallet"
                      onClick={() => {
                        navigate(
                          `/app/wallet/${
                            order.customer ? order.customer.id : null
                          }`
                        );
                      }}
                    >
                      <IconButton>
                        <Icon>wallet</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </RolePermission>
                <RolePermission permission="22-04">
                  <Grid item md={4} xs={6}>
                    <Tooltip
                      title="User Orders"
                      onClick={() => {
                        navigate(`/app/user_orders/${order.customer.id}`);
                      }}
                    >
                      <IconButton>
                        <FaShoppingBag size={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </RolePermission>
                <Grid item md={4} xs={6}>
                  {order?.orderRating?.length > 0 ? (
                    <Tooltip
                      title="Order Rating"
                      onClick={() => {
                        setOpenRatingDialog(true);
                        setRatingData(order?.orderRating[0]);
                      }}
                    >
                      <IconButton>
                        <MdOutlineStarRate />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid> */}
            </Stack>
          </Card>
        </Stack>
      ) : null}
    </Box>
  );
}

import {
  TextField,
  Grid,
  Stack,
  Button,
  Box,
  Autocomplete,
  Card,
  Chip,
  CardHeader,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { createRef, useEffect, useState } from "react";
import { uploadMultiAttachments } from "src/actions/uploadAttachments";
import {
  createGiftItems,
  getGiftItemById,
  resetGiftItemForm,
} from "src/actions/gifts/giftItem";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";
import { Divider } from "rsuite";
import { getItemOptionsList } from "src/actions/gifts/itemOptions";
import { Helmet } from "react-helmet";
import { FaGift } from "react-icons/fa";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { openVariantDialog } from "src/actions/gifts/variant";
import VariantForm from "src/pages/gifts/item/from/variantForm";
import { getItemSupplierList } from "src/actions/gifts/supplier";
import { getGiftsBrandList } from "src/actions/gifts/brand";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import filterText from "src/components/TextFilter";
import FilePondInput from "src/components/filepond";
import * as imageConversion from "image-conversion";

const GiftItemsAddForm = () => {
  const dispatch = useDispatch();

  const giftItemItems = useSelector((state) => state.giftItems);
  const loading = useSelector((state) => state.giftItems.updateReload);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );
  const _GiftSupplier = useSelector(
    (state) => state.itemSupplier.itemSupplierDataList
  );
  const _GiftBrand = useSelector(
    (state) => state.giftItemBrand.giftItemBrandList
  );
  const _GiftOptions = useSelector(
    (state) => state.itemOptions.itemOptionsDataList
  );
  const _Variant = useSelector((state) => state.variant.openDialog);

  const [files, setFiles] = useState([]);

  const [uploadType, setUploadType] = useState("");
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const [compressedBlobs, setCompressedBlobs] = useState([]);

  const cropperRef = createRef();

  const onFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    setValue("attachments1", files, {
      shouldDirty: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    dispatch(getItemOptionsList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getItemSupplierList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGiftsBrandList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    // getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: giftItemItems.addForm,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "giftItemOption",
  });

  useEffect(() => {
    reset({ ...giftItemItems.addForm });
  }, [reset, giftItemItems.addForm]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftItemForm());
    };
  }, [dispatch]);

  const [optionsAttachmentFiles, setOptionsAttachmentFiles] = useState([]);
  const [optionFiles, setOptionFiles] = useState([]);

  useEffect(() => {
    setOptionFiles(
      optionsAttachmentFiles.map((files) => (files ? files[0]?.file : null))
    );
  }, [optionsAttachmentFiles]);

  useEffect(() => {
    optionsAttachmentFiles.forEach((files, index) => {
      if (files && files[0]?.file) {
        setValue(`giftItemOption[${index}].hasAttachment`, true, {
          shouldDirty: true,
        });
      } else {
        setValue(`giftItemOption[${index}].hasAttachment`, false, {
          shouldDirty: true,
        });
      }
    });
  }, [optionsAttachmentFiles, setValue]);

  const handleUpdateFiles = (fieldIndex, newFiles) => {
    setOptionsAttachmentFiles((prevFiles) => {
      const newFileList = [...prevFiles];
      newFileList[fieldIndex] = newFiles;
      return newFileList;
    });
  };

  const onSubmit = (data) => {
    let isError = false;
    if (data.giftItemOption?.length > 0) {
      const groupedByOptionId = data.giftItemOption?.reduce((acc, obj) => {
        const { optionId, ...rest } = obj;
        if (!acc[optionId]) {
          acc[optionId] = [];
        }
        acc[optionId].push(rest);
        return acc;
      }, {});
      for (const key in groupedByOptionId) {
        const hasAttachmentValues = groupedByOptionId[key].map(
          (obj) => obj.hasAttachment
        );
        if (new Set(hasAttachmentValues).size > 1) {
          isError = true;
          break;
        }
      }
    }
    if (isError) {
      dispatch(
        showErrorSnackbar(
          "Ether include attachments for all options with the same name or remove the attachments"
        )
      );
    } else {
      var options = [];
      if (data?.giftItemOption.length > 0 || data?.giftItemOption) {
        options = data?.giftItemOption?.map((item) => ({
          giftItemOptionId: item.id,
          optionId: item.optionId,
          nameAr: item.nameAr,
          nameEn: item.nameEn,
          nameKu: item.nameKu,
          isActive: item.isActive,
          priceDifference: item.priceDifference,
          attachments: [],
        }));
      }
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`Files`, file, file.name);
      });
      formData.append("type", 4);
      dispatch(uploadMultiAttachments(formData))
        .then((response) => {
          if (response.message === "Success") {
            if (
              optionFiles.filter((value) => (value ? value : null)).length > 0
            ) {
              const optionsFormData = new FormData();
              optionFiles.map((file) =>
                file ? optionsFormData.append(`Files`, file, file.name) : null
              );
              optionsFormData.append("type", 5);
              dispatch(uploadMultiAttachments(optionsFormData))
                .then((optionsResponse) => {
                  if (optionsResponse.message === "Success") {
                    let responseIndex = 0;
                    optionFiles.forEach((file, index) => {
                      if (file) {
                        options[index].attachments.push(
                          optionsResponse.data[responseIndex].id
                        );
                        responseIndex++;
                      }
                    });
                    dispatch(
                      createGiftItems(
                        {
                          nameAr: data.nameAr,
                          nameEn: data.nameEn,
                          nameKu: data.nameKu,
                          descriptionAr: data.descriptionAr,
                          descriptionEn: data.descriptionEn,
                          descriptionKu: data.descriptionKu,
                          costPrice: data.costPrice,
                          sellPrice: data.sellPrice,
                          discountPrice: parseInt(data.discountPrice) ?? null,
                          giftItemAttachment: response.data?.map((x) => x?.id),
                          giftItemCategoryIds: data.giftItemCategoryIds,
                          giftSupplierId: data.giftSupplierId,
                          giftBrandId: data.giftBrandId,
                          giftItemOption: options,
                          filters: data.filters,
                          localExpress: data.localExpress,
                          original: data.original,
                        },
                        {
                          pageNo: pageNo + 1,
                          searchBody,
                        }
                      )
                    )
                      .then((response) => {
                        if (response.message === "Success") {
                          dispatch(getGiftItemById(response.data)).then(() =>
                            dispatch(openVariantDialog())
                          );
                          reset({ ...giftItemItems.addForm });
                          dispatch(resetGiftItemForm());
                          setValue(`giftItemAttachments`, []);
                          setValue(`giftItemAttachment`, []);
                          setValue(`giftItemOption`, []);
                          setFiles([]);
                          setImage("");
                          setOptionsAttachmentFiles([]);
                        }
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  }
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              if (response.message === "Success") {
                dispatch(
                  createGiftItems(
                    {
                      nameAr: data.nameAr,
                      nameEn: data.nameEn,
                      nameKu: data.nameKu,
                      descriptionAr: data.descriptionAr,
                      descriptionEn: data.descriptionEn,
                      descriptionKu: data.descriptionKu,
                      costPrice: data.costPrice,
                      sellPrice: data.sellPrice,
                      discountPrice: parseInt(data.discountPrice) ?? null,
                      giftItemAttachment: response.data?.map((x) => x?.id),
                      giftItemCategoryIds: data.giftItemCategoryIds,
                      giftSupplierId: data.giftSupplierId,
                      giftBrandId: data.giftBrandId,
                      giftItemOption: options,
                      filters: data.filters,
                      localExpress: data.localExpress,
                      original: data.original,
                    },
                    {
                      pageNo: pageNo + 1,
                      searchBody,
                    }
                  )
                )
                  .then((response) => {
                    if (response.message === "Success") {
                      dispatch(getGiftItemById(response.data)).then(() =>
                        dispatch(openVariantDialog())
                      );
                      reset({ ...giftItemItems.addForm });
                      dispatch(resetGiftItemForm());
                      setValue(`giftItemAttachments`, []);
                      setValue(`giftItemAttachment`, []);
                      setValue(`giftItemOption`, []);
                      setFiles([]);
                      setImage("");
                      setOptionsAttachmentFiles([]);
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      {_Variant && <VariantForm />}
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Helmet>
            <title>Add Gift Product | Orderii</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    Add Gift Product
                  </Typography>
                }
                avatar={<FaGift size={20} />}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <Box>
            <Card
              sx={{
                p: 2,
                background: "white",
                height: "calc(100vh - 180px)",
                overflowY: "scroll",
                // display: "flex",
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="giftSupplierId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={loading}
                          options={
                            _GiftSupplier.filter((x) => x.isActive !== false) ||
                            []
                          }
                          getOptionLabel={(option) => option.nameEn}
                          fullWidth
                          size="small"
                          renderInput={(params) => (
                            <TextField required {...params} label="Supplier" />
                          )}
                          onChange={(event, value) => {
                            setValue(
                              "giftSupplierId",
                              value ? value.id : null,
                              {
                                shouldDirty: true,
                              }
                            );
                          }}
                          value={
                            _GiftSupplier?.filter(
                              (x) => x.id === field.value
                            )[0] ?? null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="giftItemCategoryIds"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          disabled={loading}
                          options={_GiftCategory || []}
                          getOptionLabel={(option) => option.nameEn}
                          fullWidth
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              required={field?.value?.length <= 0}
                              {...params}
                              label="Category"
                            />
                          )}
                          onChange={(event, value) => {
                            var newIds = value.map((x) => x.id);
                            var oldIds = field.value ?? [];
                            var deselectedId = oldIds?.find(
                              (id) => !newIds.includes(id)
                            );
                            if (deselectedId) {
                              var deselectedFilters = _GiftCategory
                                .find((x) => x.id === deselectedId)
                                .filterCollections.map((x) => x.values)
                                .flat()
                                .map((x) => x.id);
                              var newFilters = watch("filters").filter(
                                (id) => !deselectedFilters.includes(id)
                              );
                              setValue("filters", newFilters, {
                                shouldDirty: true,
                              });
                            }
                            setValue(
                              "giftItemCategoryIds",
                              newIds.length > 0 ? newIds : null,
                              { shouldDirty: true }
                            );
                            setValue(
                              "giftItemCategories",
                              value.length > 0 ? value : null
                            );
                          }}
                          value={_GiftCategory?.filter((x) =>
                            field.value ? field.value?.includes(x.id) : null
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="giftBrandId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={loading}
                          options={_GiftBrand || []}
                          getOptionLabel={(option) => option.nameEn}
                          fullWidth
                          size="small"
                          renderInput={(params) => (
                            <TextField required {...params} label="Brand" />
                          )}
                          onChange={(event, value) => {
                            setValue("giftBrandId", value ? value.id : null, {
                              shouldDirty: true,
                            });
                          }}
                          value={
                            _GiftBrand?.filter(
                              (x) => x.id === field.value
                            )[0] ?? null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="costPrice"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          type="number"
                          size="small"
                          label="Cost Price (IQD)"
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="sellPrice"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          type="number"
                          size="small"
                          label="Sell Price (IQD)"
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="discountPrice"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          size="small"
                          label="Price After Discount (IQD)"
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="nameEn"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          inputProps={{
                            minLength: 4,
                          }}
                          size="small"
                          label="Name English"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("nameEn", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="nameAr"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          inputProps={{
                            minLength: 4,
                          }}
                          size="small"
                          label="Name Arabic"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("nameAr", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="nameKu"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          inputProps={{
                            minLength: 4,
                          }}
                          size="small"
                          label="Name Kurdish"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("nameKu", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="descriptionEn"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          multiline
                          rows={7}
                          size="small"
                          label="Description English"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("descriptionEn", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="descriptionAr"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          multiline
                          rows={7}
                          size="small"
                          label="Description Arabic"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("descriptionAr", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="descriptionKu"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          multiline
                          rows={7}
                          size="small"
                          label="Description Kurdish"
                          fullWidth
                          onChange={(e) => {
                            const filteredValue = filterText(e.target.value);
                            setValue("descriptionKu", filteredValue, {
                              shouldDirty: true,
                            });
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="localExpress"
                      control={control}
                      render={({ field }) => (
                        <Tooltip
                          title={watch("localExpress") ? "Active" : "Inactive "}
                          aria-label="active_deactivate"
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                onChange={(e) => {
                                  setValue("localExpress", e.target.checked, {
                                    shouldDirty: true,
                                  });
                                }}
                              />
                            }
                            label="Local Express"
                          />
                        </Tooltip>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="original"
                      control={control}
                      render={({ field }) => (
                        <Tooltip
                          title={watch("original") ? "Active" : "Inactive "}
                          aria-label="active_deactivate"
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                onChange={(e) => {
                                  setValue("original", e.target.checked, {
                                    shouldDirty: true,
                                  });
                                }}
                              />
                            }
                            label="Original"
                          />
                        </Tooltip>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider>
                      <Chip label="Product Attachments" sx={{ my: 2 }} />
                    </Divider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <ToggleButtonGroup
                      value={uploadType}
                      exclusive
                      onChange={(e, newUploadType) => {
                        if (newUploadType !== null) {
                          setUploadType(newUploadType);
                        }
                      }}
                      aria-label="upload type"
                    >
                      <ToggleButton
                        value="crop"
                        aria-label="crop images before upload"
                        color="primary"
                      >
                        Crop Images Before Upload
                      </ToggleButton>
                      <ToggleButton
                        value="upload"
                        aria-label="upload images directly"
                        color="secondary"
                      >
                        Upload Images Directly
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {uploadType === "crop" ? (
                    <Grid item xs={12} md={12}>
                      <div className="imageCrop">
                        <div style={{ width: "100%" }}>
                          <TextField
                            sx={{ mb: 1 }}
                            size="small"
                            type="file"
                            onChange={onFileChange}
                            fullWidth
                          />
                          {image && (
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <Typography color="primary" fontWeight="bold">
                                  * You can zoom in and out the image with
                                  scroll
                                </Typography>
                                <Cropper
                                  ref={cropperRef}
                                  style={{ height: 400 }}
                                  zoomTo={0.5}
                                  initialAspectRatio={1}
                                  preview=".img-preview"
                                  src={image}
                                  viewMode={1}
                                  minCropBoxHeight={10}
                                  minCropBoxWidth={10}
                                  background={false}
                                  responsive={true}
                                  autoCropArea={1}
                                  checkOrientation={false}
                                  guides={true}
                                  aspectRatio={1}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div
                                  className="box"
                                  style={{ width: "50%", float: "right" }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    mb={1}
                                    justifyContent="center"
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      // style={{ float: "right" }}
                                      onClick={getCropData}
                                    >
                                      Crop Image
                                    </Button>
                                  </Stack>
                                  <div
                                    className="img-preview"
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      height: "400px",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                              >
                                <div className="box">
                                  {cropData.length > 2 ? (
                                    <img
                                      style={{ width: "400px" }}
                                      src={cropData}
                                      alt="cropped"
                                    />
                                  ) : null}
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </div>

                        <br style={{ clear: "both" }} />
                      </div>
                    </Grid>
                  ) : null}
                  {uploadType === "crop" || uploadType === "upload" ? (
                    <Grid item xs={12} md={12}>
                      <FilePondInput
                        acceptedFileTypes={["image/*"]}
                        allowImageTransform={true}
                        allowImageResize={true}
                        allowImageCrop={true}
                        imageTransformOutputMimeType="image/jpeg"
                        credits={false}
                        required={
                          giftItemItems.updateForm?.giftItemAttachments
                            ?.length > 0
                            ? false
                            : true
                        }
                        files={files}
                        onupdatefiles={(files) => {
                          setFiles(files.map((file) => file?.file));
                        }}
                        allowMultiple={true}
                        maxFiles={10}
                        name="file"
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        allowImagePreview={true}
                        imagePreviewHeight={300}
                        imageTransformAfterCreateBlob={(blob) =>
                          new Promise(async (resolve) => {
                            if (blob && blob.type) {
                              try {
                                const compressedBlob =
                                  await imageConversion.compress(blob, {
                                    quality: 1,
                                    type: "image/jpeg",
                                  });
                                setCompressedBlobs((prevBlobs) => {
                                  const newBlobs = [
                                    ...prevBlobs,
                                    compressedBlob,
                                  ];
                                  setValue("mainAttachments", newBlobs, {
                                    shouldDirty: true,
                                  });
                                  return newBlobs;
                                });
                                resolve(compressedBlob);
                              } catch (error) {
                                console.error("Error compressing blob:", error);
                                resolve(blob);
                              }
                            } else {
                              console.error("Invalid blob:", blob);
                              resolve(blob);
                            }
                          })
                        }
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Product Options" sx={{ my: 2 }} />
                    </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    {fields.map((item, index) => (
                      <Grid container spacing={2} key={index} sx={{ my: 2 }}>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name={`giftItemOption[${index}].optionId`}
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disabled={loading}
                                options={_GiftOptions || []}
                                getOptionLabel={(option) => option.nameEn}
                                fullWidth
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label="Options"
                                  />
                                )}
                                onChange={(event, value) => {
                                  setValue(
                                    `giftItemOption[${index}].optionId`,
                                    value ? value.id : null
                                  );
                                }}
                                value={
                                  _GiftOptions?.filter(
                                    (x) => x.id === field.value
                                  )[0] ?? null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name={`giftItemOption[${index}].priceDifference`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                required
                                type="number"
                                size="small"
                                label="Price Difference (IQD)"
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              remove(index);
                              setOptionsAttachmentFiles(
                                (prevOptionsAttachmentFiles) =>
                                  prevOptionsAttachmentFiles?.filter(
                                    (file, i) => i !== index
                                  )
                              );
                            }}
                          >
                            Remove
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name={`giftItemOption[${index}].nameEn`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                required
                                size="small"
                                label="Name English"
                                fullWidth
                                onChange={(e) => {
                                  const filteredValue = filterText(
                                    e.target.value
                                  );
                                  setValue(
                                    `giftItemOption[${index}].nameEn`,
                                    filteredValue,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name={`giftItemOption[${index}].nameAr`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                required
                                size="small"
                                label="Name Arabic"
                                fullWidth
                                onChange={(e) => {
                                  const filteredValue = filterText(
                                    e.target.value
                                  );
                                  setValue(
                                    `giftItemOption[${index}].nameAr`,
                                    filteredValue,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name={`giftItemOption[${index}].nameKu`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                required
                                size="small"
                                label="Name Kurdish"
                                fullWidth
                                onChange={(e) => {
                                  const filteredValue = filterText(
                                    e.target.value
                                  );
                                  setValue(
                                    `giftItemOption[${index}].nameKu`,
                                    filteredValue,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Controller
                            name={`giftItemOption[${index}].hasAttachment`}
                            control={control}
                            defaultValue={[]}
                            render={({ field: { onChange, value } }) => (
                              <FilePondInput
                                acceptedFileTypes={[
                                  "image/jpeg",
                                  "image/png",
                                  "image/jpg",
                                  "image/pdf",
                                  "image/svg",
                                  "image/webp",
                                  "image/tiff",
                                  "image/bmp",
                                  "image/heic",
                                  "image/heif",
                                  "image/JPEG",
                                  "image/PNG",
                                  "image/JPG",
                                  "image/PDF",
                                  "image/SVG",
                                  "image/WEBP",
                                  "image/TIFF",
                                  "image/BMP",
                                  "image/HEIC",
                                  "image/HEIF",
                                ]}
                                credits={false}
                                files={optionsAttachmentFiles[index] || []}
                                onupdatefiles={(newFiles) => {
                                  handleUpdateFiles(index, newFiles);
                                }}
                                name="file"
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          append({
                            optionId: null,
                            nameAr: "",
                            nameEn: "",
                            nameKu: "",
                            priceDifference: 0,
                            attachments: [],
                            hasAttachment: false,
                          });
                        }}
                      >
                        Add New Option
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider>
                      <Chip label="Filter Collection" sx={{ my: 2 }} />
                    </Divider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {watch("giftItemCategories")?.map((category, index) =>
                      category?.filterCollections?.length > 0 ? (
                        <Box key={index} sx-={{ py: 25 }}>
                          <Typography variant="h3" sx={{ mb: 2 }}>
                            Category: {category?.nameEn}
                          </Typography>
                          {category?.filterCollections?.map((option, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid
                                item
                                xs={2}
                                display="flex"
                                alignItems="center"
                              >
                                <Typography variant="h4" key={option.id}>
                                  {option.nameEn}:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                {option?.values?.map((value, index) => (
                                  <Controller
                                    key={index}
                                    name="filters"
                                    control={control}
                                    render={({ field }) => (
                                      <FormControlLabel
                                        sx={{ mx: 2 }}
                                        key={value.id}
                                        control={
                                          <Checkbox
                                            value={value.id}
                                            checked={field.value.includes(
                                              value.id
                                            )}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setValue(
                                                  "filters",
                                                  [...field.value, value.id],
                                                  {
                                                    shouldDirty: true,
                                                  }
                                                );
                                              } else {
                                                setValue(
                                                  "filters",
                                                  field.value.filter(
                                                    (id) => id !== value.id
                                                  ),
                                                  {
                                                    shouldDirty: true,
                                                  }
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label={value.nameEn}
                                      />
                                    )}
                                  />
                                ))}
                              </Grid>
                              <Grid item xs={12}>
                                <Divider sx={{ my: 1 }} />
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      ) : null
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack
                      sx={{ marginTop: 2 }}
                      direction="row"
                      spacing={2}
                      justifyContent="end"
                      alignItems="center"
                    >
                      <LoadingButton
                        sx={{ width: 200 }}
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}
                        disabled={!isDirty}
                      >
                        Save
                      </LoadingButton>
                      {
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => {
                            setValue(`giftItemAttachments`, []);
                            setValue(`giftItemAttachment`, []);
                            setValue(`giftItemOption`, []);
                            reset({ ...giftItemItems.addForm });
                          }}
                        >
                          Clear Text
                        </Button>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default GiftItemsAddForm;
